import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../authentication/authContext.tsx';
import { UserData } from '../../interfaces/userDataInterface.ts';
import config from '../../configs/config.js';
import Modal from '@mui/material/Modal';
import { getInfoPlanos } from '../../services/planoService.ts'
import QRcode from '../../assets/QRcode.png'
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';

const ModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "35vh",
  height: '70%',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: "white",
  overflow: "auto"
};

const terms = config.terms;

export default function Planos() {

  const [planosInfo, setPlanosInfo] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
  const handleOpenModal = () => {
    setCaptchaValue('invalid');
    setTermosDeUsoAceitos(false);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    setOpen(true);
  }

  const [userData, setUserData] = React.useState<UserData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
    const fetchPlanoData = async () => {
      try {
        const result = await getInfoPlanos();
        if (result) { setPlanosInfo(result); }
      } catch (error) {
        // Handle errors
      }
    };
    fetchPlanoData();
  }, []);

  const cardStyle = {
    height: "100%",
    width: "80%",
    borderRadius: "8%",
    color: "#fff", // Texto branco
    border: "1px solid #555", // Borda um pouco mais clara para destacar
    boxShadow: "0 10px 8px rgba(0, 0, 0, 0.5)",
    overflow: "auto"
  };

  const { logout } = useAuth();

  const subscribe = async (plano: string) => {
    let success: boolean = false;
    const bearerToken = localStorage.getItem("token");
    if (bearerToken == null || userData == null) {
      toast("Erro: Por favor efetue um novo login.");
    } else {
      try {
        const response = await fetch(config.apiUrl + '/subscription/subscribe', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uid: userData.id,
            plano: plano
          })
        });
        if (response.status === 401) {
          navigate("/Home");
          logout();
          toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
          return false;
        }
        if (!response.ok) {
          const errorMessage = await response.text();
          toast.error(errorMessage);
        } else {
          const data = await response.json();
          userData.plano = data;
          localStorage.setItem('userData', JSON.stringify(userData)); // Atualiza dados do plano
          success = true;
          navigate('/Home');
          toast('❤ Seu plano foi assinado com sucesso. \n Obrigado pelo suporte!', {
            autoClose: 15000
          });
        }
      } catch (error) {
        toast.error("Ops! Falha ao assinar plano. Por favor contacte um administrador ou tente novamente mais tarde.");
      }
    }
    return success;
  }

  const buttonText = (planoButton: number) => {
    let buttonString = "";

    if (planoButton == userData?.plano.plano) {
      buttonString = 'Estender Plano';
    } else {
      buttonString = 'Assinar Plano';
    }
    return buttonString;
  }

  const [waitingForPayment, setWaitingForPayment] = React.useState(false);
  const waitForPaymentRepeatedly = (pid: string, plano: number) => {
    let approved = false;
    setWaitingForPayment(true);
    const waitForPayment = async () => {
      const bearerToken = localStorage.getItem("token");
      if (bearerToken == null || userData == null) {
        toast("Erro: Por favor efetue um novo login.");
      } else {
        try {
          const response = await fetch(config.apiUrl + `/Payment/CheckPayment?uid=${userData.id}&plano=${plano}&pid=${pid}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json'
            }
          });
          if (response.status === 401) {
            navigate("/Home");
            logout();
            toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
            return false;
          }
          if (!response.ok) {
            const errorMessage = await response.text();
          } else {
            //const data = await response.json();
            approved = true;
            setWaitingForPayment(false);
            const data = await response.json();
            userData.plano = data;
            localStorage.setItem('userData', JSON.stringify(userData)); // Atualiza dados do plano
            //success = true;
            navigate('/Home');
            toast('❤ Seu plano foi assinado com sucesso. \n Obrigado pelo suporte!', {
              autoClose: 15000
            });
            //subscribe(plano); // pagamento ja confirmado, faz o subscribe
          }
        } catch (error) {
          toast.error("Ops! Falha ao assinar plano. Por favor, contate um administrador ou tente novamente mais tarde.");
        }
      }
    }

    // Inicia o temporizador
    let intervalId;
    let elapsedTime = 0;
    const intervalDuration = 10000; // 10 segundos
    const maxTime = 10 * 60 * 1000; // 10 minutos em milissegundos

    const startTimer = () => {
      intervalId = setInterval(async () => {
        if (elapsedTime >= maxTime || approved == true) {
          clearInterval(intervalId);
        } else {
          await waitForPayment();
          elapsedTime += intervalDuration;
        }
      }, intervalDuration);
    }

    if (approved == false)
      startTimer(); // Inicia o temporizador
  }

  const [creatingPayment, setCreatingPayment] = React.useState(false);
  const [qrCode, setQrCode] = React.useState('');
  const [qrCodeBase64, setQrCodeBase64] = React.useState('');
  const createPayment = async (plano: number) => {
    setCreatingPayment(true);
    let success: boolean = false;
    const bearerToken = localStorage.getItem("token");

    if (bearerToken == null || userData == null) {
      toast("Erro: Por favor efetue um novo login.");
    } else {
      try {
        const response = await fetch(config.apiUrl + `/Payment/CreatePayment?captchaResponse=${captchaValue}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uid: userData.id,
            plano: plano
          })
        });
        if (response.status === 401) {
          navigate("/Home");
          logout();
          toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
          setCreatingPayment(false);
          return false;
        }
        if (!response.ok) {
          const errorMessage = await response.text();
          //toast.error(errorMessage);
        } else {
          const data = await response.json();
          setQrCodeBase64(data.qrCodeBase64);
          setQrCode(data.qrCode);
          waitForPaymentRepeatedly(data.pid, plano);
          success = true;
        }
      } catch (error) {
        toast.error("Ops! Falha ao assinar plano. Por favor contacte um administrador ou tente novamente mais tarde.");
      }
    }
    setCreatingPayment(false);
    return success;
  }

  const [captchaValue, setCaptchaValue] = React.useState('invalid');
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const cancelaPagamento = () => {
    const confirmation = window.confirm("Tem certeza de que deseja cancelar o pagamento?");

    if (confirmation) {
      setWaitingForPayment(false);
      //setCancelPayment(true);
      handleClose(null, "close");
    }
  }

  const [precoPlanoSelecionado, setPrecoPlanoSelecionado] = React.useState("");
  const [planoSelecionado, setPlanoSelecionado] = React.useState(0);
  const assinarNovoPlano = async () => { // Recebe Pagamento --> createPayment --> waitForPaymentRepeatedly --> subscribe

    if (!captchaValue || captchaValue == "invalid") { toast.error("Por favor complete o Captcha."); return; }

    if (termosDeUsoAceitos == false) { toast.error("Ops! É preciso aderir aos termos de uso."); return; }

    setPageIndex(true); // Troca a exibição da modal
    setPrecoPlanoSelecionado(getPrecoPlano(planoSelecionado));
    let paymentCreated = await createPayment(planoSelecionado);
    if (paymentCreated) {
      handleOpenModal();
    } else {
      handleClose(null, "close");
      setPageIndex(false); // Troca a exibição da modal
      toast.error("Falha ao gerar pagamento. Tente novamente mais tarde.");
    }
  }

  const handleCheckboxChange = (event) => {
    setTermosDeUsoAceitos(event.target.checked);
  };

  const [pageIndex, setPageIndex] = React.useState(false);
  const [termosDeUsoAceitos, setTermosDeUsoAceitos] = React.useState(false);
  const handleAssinarPlanoClick = (plano: number) => {
    setPageIndex(false);
    setPlanoSelecionado(plano);
    if (userData == null) { // Se não estiver logado
      toast.error("É preciso estar cadastrado para assinar um plano!")
    } else if (userData?.plano.plano == plano) { // Se clicar no plano que já possui
      //navigate(`/Perfil`); // Estender plano pelo perfil
      alert('Desculpe, a função solicitada ainda não está disponível. Por favor, considere renovar seu plano quando ele expirar.');
    } else { // Nova assinatura
      handleOpenModal();
      //assinarNovoPlano(plano);
    }
  }

  const imgStyle = {
    '--s': '50px',
    '--t': '5px',
    '--g': '20px',
    padding: 'calc(var(--g) + var(--t))',
    outline: 'var(--t) solid #94928e',
    outlineOffset: 'calc(-1 * var(--t))',
    mask: `conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 / calc(100% - var(--s)) calc(100% - var(--s)), linear-gradient(#000 0 0) content-box`,
    transition: '.4s'
  };

  const buttonDisabled = (plano: number) => {
    let result = false;
    if (userData != null && userData?.plano.plano != 0 && userData?.plano.plano != plano) {
      result = true;
    }
    return result;
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(qrCode)
      .then(() => {
        toast.info("Código PIX copiado.");
      })
      .catch(err => {
        //console.error('Erro ao copiar o texto:', err);
      });
  };

  const getPrecoPlano = (pID: number) => {

    let preco = "N/A";

    if (planosInfo.length > 0) {
      let plano = JSON.parse(planosInfo);

      // Encontrar o objeto com id igual a pID
      plano = plano.find(item => item.id === pID);
      preco = plano.preco.toFixed(2).toString().replace(/\./g, ',');
    }

    return preco;
  }

  const recaptchaRef = React.useRef<ReCAPTCHA>();

  return (
    <Box>
      <Card style={{ height: "73%", margin: "4%", borderRadius: "2%", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} variant="outlined">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{
            ...ModalStyle,
            backgroundColor: "rgba(89, 89, 88, 1)",
            width: "20%",
            borderRadius: "2%",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}>
            {/* Faixa no topo */}
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "25px",
              backgroundColor: "gray",
              textAlign: "center",
              color: "white"
            }}>
              Pagamento - PIX
            </div>
            {pageIndex == true && creatingPayment == false ? (
              <div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}>
                  {qrCodeBase64 ? (
                    <img src={`data:image/png;base64, ${qrCodeBase64}`} width="50%" alt="Descrição da imagem" style={imgStyle} />
                  ) : (
                    <p>Carregando QR code...</p>
                  )}
                </div>
                <hr style={{ width: "90%", marginTop: "10%", opacity: "0.5" }} />
                <div style={{ textAlign: 'center' }}>
                  <h4 style={{ marginTop: '1%', marginBottom: '1%', color: 'white' }}>Copia e cola:
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input
                        disabled
                        id="textInput"
                        type="text"
                        value={qrCode}
                        style={{ marginRight: '10px', marginTop: "1%" }}
                      />
                      <button onClick={handleCopyToClipboard}>Copiar</button>
                    </div>
                  </h4>
                </div>
                <hr style={{ width: "90%", marginTop: "1%", opacity: "0.5" }} />
                <h4 style={{ marginTop: "1%", marginBottom: "1%", color: "white", textAlign: "center" }}>Usuário: {userData?.usuario}</h4>
                <hr style={{ width: "90%", marginTop: 0, opacity: "0.5" }} />
                <h4 style={{ marginTop: "1%", marginBottom: "1%", color: "white", textAlign: "center" }}>Plano selecionado: {planoSelecionado == 1 ? 'Basic' : planoSelecionado == 2 ? 'Standard' : 'Professional'}</h4>
                <hr style={{ width: "90%", marginTop: 0, opacity: "0.5" }} />
                <h4 style={{ marginTop: "1%", marginBottom: "1%", color: "white", textAlign: "center" }}>Preço: R${precoPlanoSelecionado}</h4>
                <hr style={{ width: "90%", marginTop: 0, opacity: "0.5" }} />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {waitingForPayment == true ? (
                    <div>                      
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ marginTop: "1%", marginRight: "10px" }}>Aguardando Pagamento</Button>
                        <CircularProgress />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ marginTop: "2%", color: "red", marginRight: "10px" }} onClick={cancelaPagamento}>Cancelar</Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Button style={{ marginTop: "2%", color: "red", marginRight: "10px" }} onClick={() => { handleClose(null, "close") }}>Fechar</Button>
                      <Button style={{ marginTop: "1%", color: "green", marginRight: "10px" }}>PAGAMENTO APROVADO</Button>
                    </>
                  )}
                </div>
                <p style={{ fontSize: "11px", color: "red", opacity: "0.9", margin: 0, marginTop: "3%", textAlign: "center" }}>PIX é o único método de pagamento aceito atualmente.</p>
                <p style={{ fontSize: "11px", color: "red", opacity: "0.9", margin: 0, marginBottom: "1%", textAlign: "center" }}>Não ofertamos reembolso.</p>
              </div>

            ) : (
              <div>
                {creatingPayment == true ?
                  (
                    <CircularProgress />
                  ) :
                  (
                    <div>
                      <textarea
                        value={terms}
                        readOnly
                        rows={10} // Define o número de linhas
                        cols={50} // Define o número de colunas
                        style={{
                          resize: 'none',
                          width: '95%',
                          minHeight: '30vh',
                          backgroundColor: '#222', // Dark background color
                          color: '#fff', // White text color
                          padding: "5%",
                          border: 'none' // Remove border (optional)
                        }}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox color="secondary" checked={termosDeUsoAceitos} onChange={handleCheckboxChange} />
                        <Typography variant="body2">
                          Li e concordo com os termos e condições de uso
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          className="g-recaptcha"
                          sitekey="6LcNDvUpAAAAAH3EGJkU-aFbBOSnEiP3EvG-c23w"
                          theme="dark"
                          size="normal"
                          onChange={handleCaptchaChange}
                        />
                        <Button onClick={() => { assinarNovoPlano(planoSelecionado) }} style={{ marginTop: "5%" }}>Gerar Pagamento via PIX</Button>
                        <Button style={{ marginTop: "2%", color: "red" }} onClick={cancelaPagamento}>Cancelar</Button>
                      </div>
                    </div>
                  )}
              </div>

            )}

          </Box>
        </Modal>

        <>
          <Typography id="modal-modal-title" style={{ marginBottom: "1%" }} variant="h3">
            Planos Disponíveis
          </Typography>
          <p style={{ margin: 0 }}>Explore nossos planos e inscreva-se hoje mesmo para acesso ilimitado ao melhor sistema de consultas do Brasil.</p>
          <p style={{ margin: "0.5%" }}>Desbloqueie uma variedade de recursos exclusivos e aproveite uma experiência de consulta sem limites.</p>
          <Grid container spacing={1} style={{ marginTop: "2%", justifyContent: "center" }}>
            <Grid item xs={12} md={3} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={{ ...cardStyle, opacity: userData != null && userData?.plano.plano != 1 && userData?.plano.plano != 0 ? "0.7" : "" }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Basic {userData?.plano.plano == 1 ? (<Typography variant="body2" style={{ color: "gray" }}>(PLANO ATUAL)</Typography>) : (<></>)}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ marginTop: "15%" }}>
                        R$  {getPrecoPlano(1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginLeft: "3vh" }}>
                      <Typography variant="body2" style={{ marginTop: "15%" }}>
                        Consultas ilimitadas por <Typography variant="h5" component="span">24h</Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleAssinarPlanoClick(1)} size="medium"
                    disabled={buttonDisabled(1)}
                    color="secondary" variant="outlined">{buttonText(1)}</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={3} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={{ ...cardStyle, opacity: userData != null && userData?.plano.plano != 2 && userData?.plano.plano != 0 ? "0.7" : "" }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Standard {userData?.plano.plano == 2 ? (<Typography variant="body2" style={{ color: "gray" }}>(PLANO ATUAL)</Typography>) : (<></>)}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ marginTop: "15%" }}>
                        R$ {getPrecoPlano(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginLeft: "3vh" }}>
                      <Typography variant="body2" style={{ marginTop: "15%" }}>
                        Consultas ilimitadas por <Typography variant="h5" component="span">7 dias.</Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleAssinarPlanoClick(2)} size="medium"
                    disabled={buttonDisabled(2)} color="secondary" variant="outlined">
                    {buttonText(2)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={3} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={{ ...cardStyle, opacity: userData != null && userData?.plano.plano != 3 && userData?.plano.plano != 0 ? "0.7" : "" }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Professional {userData?.plano.plano == 3 ? (<Typography variant="body2" style={{ color: "gray" }}>(PLANO ATUAL)</Typography>) : (<></>)}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ marginTop: "15%" }}>
                        R$ {getPrecoPlano(3)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginLeft: "3vh" }}>
                      <Typography variant="body2" style={{ marginTop: "15%" }}>
                        Consultas ilimitadas por <Typography variant="h5" component="span">1 mês.</Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleAssinarPlanoClick(3)}
                    size="medium" disabled={buttonDisabled(3)} color="secondary" variant="outlined">
                    {buttonText(3)}</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <p style={{ marginTop: "3%", marginBottom: "2%" }}>Pagamento via PIX, 100% seguro e aprovado automaticamente.</p>
        </>
      </Card>
    </Box>
  );
}