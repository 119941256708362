import config from '../configs/config'
import { toast } from 'react-toastify';

export const getInfoPlanos = async () => {
    try {
      const response = await fetch(config.apiUrl + '/subscription/getInfoPlanos', {
        method: 'GET'
      });
      if (!response.ok) {
        const errorMsg = await response.text();
        toast.error(errorMsg);
      } else {
        const data = await response.json(); // Parse the JSON response
        if(data){
            return JSON.stringify(data);
        }
      }
    } catch (error) {
      toast.error("Ops! Falha de comunicação com o servidor.");
    }
  }