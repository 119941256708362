import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAuth } from '../authentication/authContext.tsx';
import { useNavigate } from 'react-router-dom';
import config from '../../configs/config.js';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './scanResultsPage.css';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import img_placa from '../../assets/placa_antiga.png'
import img_placa_nova from '../../assets/placa_nova.png'
import { v4 as uuidv4 } from 'uuid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import mapsImg from '../../assets/maps.png'
import wppImg from '../../assets/whats.png'
import img_empresa from '../../assets/empresa_img.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../../assets/icon.png'
import pdf_header from '../../assets/pdf_header.png'
import { AnimatedTree } from 'react-tree-graph';
import user_pic from '../../assets/219969.png'
import { Tree, TreeNode } from 'react-organizational-chart';

const filhosComuns = [
  { label: 'Sam Doe' },
  { label: 'Chris Doe' },
  { label: 'Sara Doe' },
  { label: 'Lilly Doe' }
];

const treeData = {
  label: 'Avós',
  children: [
    {
      label: 'Pai',
      children: filhosComuns
    },
    {
      label: 'Mãe',
      children: filhosComuns
    },
    {
      label: 'EU',
      children: [
        {
          label: 'Irmãos',
          children: filhosComuns
        }
      ]
    }
  ]
};







const Node = ({ children }) => (
  <div style={{ border: '1px solid white', padding: '5px', borderRadius: '5px', backgroundColor: "#1E1E1E", margin: "5px" }}>
    {children}
  </div>
);

const renderTree = (node) => {
  return (
    <TreeNode label={<Node><a href='/home' style={{color: "white"}}>{node.label}</a></Node>}>
      {node.children && node.children.map(child => renderTree(child))}
    </TreeNode>
  );
};

const OrganizationalTree = ({ data }) => {
  return (
    <Tree
      lineWidth={'1px'}
      lineColor={'white'}
      lineBorderRadius={'10px'}
      label={<Node>{data.label}</Node>}
    >
      {data.children && data.children.map(child => renderTree(child))}
    </Tree>
  );
};

const ModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  maxHeight: "80%",
  minWidth: "40vh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: "white"
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000000', // Use your desired background color
    color: '#ffffff', // White color for text
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#272929',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#121212', // Background color for even rows
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface ResponsiveAppBarProps {
  openModal: () => void;
  swapTab: (value: string) => void;
}


// Função para formatar a data como uma string legível no formato DD/MM/YYYY
const formatDate = (dateString: string | undefined) => {
  if (dateString == undefined || dateString.length == 0)
    return '';

  const date = new Date(dateString);

  // Verifica se a conversão foi bem-sucedida e retorna a data formatada
  if (!isNaN(date.getTime())) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Adiciona um zero à esquerda se o dia ou mês tiver apenas um dígito
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${formattedDay}/${formattedMonth}/${year}`;
  } else {
    // Se a conversão falhar, retorna uma string vazia ou um valor padrão
    return ""; // ou qualquer valor padrão que você deseja retornar em caso de data inválida
  }
};

function Row(props: { row: any, moradores: any }) {
  const { row, moradores } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.Municipio}</TableCell>
        <TableCell align="center">{row.Bairro}</TableCell>
        <TableCell align="center">{row.Logradouro}</TableCell>
        <TableCell align="center">{row.Numero}</TableCell>
        <TableCell align="center">
          <button
            onClick={() => window.open('https://www.google.com.br/maps/place/' + row.Logradouro + '+' + row.Numero + '+-+' + row.CEP, '_blank')}
            style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
            <img src={mapsImg} height="50vh" alt="Icon" style={{ filter: 'grayscale(90%)' }} />
          </button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Moradores
              </Typography>
              <Table size="small" id="jspf-print-table" aria-label="Moradores">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Cpf</TableCell>
                    <TableCell>Nascimento</TableCell>
                    <TableCell>Sexo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {moradores
                    .filter(inner_row => inner_row.Numero === row.Numero)
                    .map(inner_row => (
                      <StyledTableRow key={uuidv4()}>
                        <StyledTableCell align="left">{inner_row.Cpf && inner_row.Cpf.length > 0 ? (<a href={`/Scan-result?module=CPF&data=${inner_row.Cpf}&optValue=`}>{inner_row.Nome}</a>) : (<>{inner_row.Nome}</>)}</StyledTableCell>
                        <StyledTableCell align="left">{inner_row.Cpf}</StyledTableCell>
                        <StyledTableCell align="left">{formatDate(inner_row.Nascimento)}</StyledTableCell>
                        <StyledTableCell align="left">{inner_row.Sexo}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ScanResult({ openModal, swapTab }: ResponsiveAppBarProps) {
  const [value, setValue] = React.useState('2');
  const [loading, setLoading] = useState(true); // Initially set loading to true
  let [searchParams, setSearchParams] = useSearchParams();

  const reportTemplateRef = useRef(null);
  const downloadPdf = async (module?: string) => {

    await setCnpjExpandedItems(['1', '2']);

    const doc = new jsPDF();

    //const imgWidth = 50; // Adjust image width as needed
    //const imgHeight = pdf_header.height * (imgWidth / pdf_header.width); // Maintain aspect ratio
    doc.addImage(pdf_header, 'PNG', 5, 10, 200, 15);

    //doc.text("https://life-scan.info", 50, 50);
    doc.setFontSize(14);
    doc.setTextColor(134, 175, 235);
    doc.textWithLink("https://life-scan.info", 85, 40, { url: "https://life-scan.info" });
    doc.setTextColor(0, 0, 0);
    // Obter a data e hora atuais
    const currentDateTime = new Date();
    // Formatar a data e hora como string
    const options = {
      timeZone: 'America/Sao_Paulo', // Fuso horário de Brasília
      timeZoneName: 'short', // Exibir o nome do fuso horário abreviado, se desejado
      hour12: false, // Formato de 24 horas
    };

    // Formatar a data e hora como string no fuso horário de Brasília
    const formattedDateTime = currentDateTime.toLocaleString('pt-BR', options);
    doc.text(`Consulta de dados públicos realizada no dia ${formattedDateTime}`, 30, 50);

    const tables = document.querySelectorAll('#jspf-print-table') as NodeListOf<HTMLTableElement>;

    tables.forEach((table, index) => {
      const ariaLabel = table.getAttribute('aria-label') ?? `Table ${index + 1}`;

      // Calculate startY for the title and table
      const startYTitle = (index === 0 ? 70 : doc.previousAutoTable.finalY + 20); // Space before the title
      const startYTable = startYTitle + 5; // Space between title and table

      // Add title before each table
      // Adicionar retângulo azul claro
      doc.setFillColor(230, 238, 250); // Cor azul claro (RGB)
      doc.rect(13, startYTitle - 6, 185, 8, 'F'); // Posição e tamanho do retângulo
      doc.setFontSize(15);
      doc.text(`* ${ariaLabel}`, 14, startYTitle);

      // Generate table using jsPDF-AutoTable
      autoTable(doc, {
        html: table,
        startY: startYTable,
        styles: module == "CNPJ" ? { overflow: 'linebreak', fontSize: 5 } : {},
        columnStyles: { text: { cellWidth: 'auto' } },
        // split overflowing columns into pages 
        //horizontalPageBreak: true
      });

      // Check if index is the last one
      if (index === tables.length - 1) {
        const endText = "Atenção: O uso indevido dos dados apresentados resultará no banimento permanente da sua conta.";
        doc.setFontSize(12);
        doc.text(endText, 5, doc.previousAutoTable.finalY + 20);
      }

    });

    doc.save(`LIFE_SCAN_${formattedDateTime}.pdf`);
  };




  const [serasaIrpfRows, setSerasaIrpfRows] = useState([{}]);
  const [moradoresRows, setMoradoresRows] = useState([{}]);
  const [emailData, setEmailData] = useState([{}]);
  const [veiculoData, setVeiculoData] = useState([{}]);
  const [parentesRows, setParentesRows] = useState([{}]);
  const [telefoneRows, setTelefoneRows] = useState([{}]);
  const [consultaRows, setConsultaRows] = useState([{}]);
  const [enderecoRows, setEnderecoRows] = useState([{}]);
  const [enderecoCepRows, setEnderecoCepRows] = useState([{}]);
  const [documentosRows, setDocumentosRows] = useState([{}]);
  const [cnpjRows, setCnpjRows] = useState([{}]);
  const [cnpjSociosRows, setCnpjSociosRows] = useState([{}]);
  const [posseEmpresaRows, setPosseEmpresaRows] = useState([{}]);
  const [tituloEleitorRows, setTituloEleitorRows] = useState([{}]);
  const [poderAquisitivoRows, setPoderAquisitivoRows] = useState([{}]);
  const [empregoRows, setEmpregoRows] = useState([{}]);
  const [scoreRows, setScoreRows] = useState([{}]);

  const [enderecoCepResultsLimited, setEnderecoCepResultsLimited] = useState(false);

  const handleCnpjResults = (responseData: any) => {
    const { rfCnpjRecord: cnpjRecord = {}, sociosRecord: sociosRecord = {} } = responseData;
    setCnpjRows(cnpjRecord);

    if (sociosRecord) {
      const sociosRows = sociosRecord ? [
        ...sociosRecord.map(({ identificador_de_socio, nome_socio, cnpj_cpf_socio, qualificacao_socio,
          data_entrada_sociedade, pais, representante_legal, nome_representante, qualificacao_representante_legal,
          faixa_etaria, cnpj_basico, razao_social, natureza_juridica, qualificacao_responsavel, porte_empresa,
          ente_federativo_responsavel, capital_social
        }) =>
        ({
          IdentificadorSocio: identificador_de_socio || '',
          NomeSocio: nome_socio || '',
          CnpjCpfSocio: cnpj_cpf_socio || '',
          QualificacaoSocio: qualificacao_socio || '',
          DataEntradaSociedade: data_entrada_sociedade || '',
          Pais: pais || '',
          RepresentanteLegal: representante_legal || '',
          NomeRepresentante: nome_representante || '',
          QualificacaoRepresentanteLegal: qualificacao_representante_legal || '',
          FaixaEtaria: faixa_etaria || '',
          CnpjBasico: cnpj_basico || '',
          RazaoSocial: razao_social || '',
          NaturezaJuridica: natureza_juridica || '',
          QualificacaoResponsavel: qualificacao_responsavel || '',
          PorteEmpresa: porte_empresa || '',
          EnteFederativoResponsavel: ente_federativo_responsavel || '',
          CapitalSocial: capital_social || ''
        }))
      ] : [{}]

      const filteredSociosRows = sociosRows.filter(row => Object.values(row).some(value => value !== ''));
      setCnpjSociosRows(filteredSociosRows);
    }
  }

  const handleFamiliaResults = (responseData: any) => {
    const { contatoRecord: contato = {}, datasusRecord: datasus = {}, serasaMapaParentalRecord: mapa_parental = [] } = responseData;
  }

  const handleCpfResults = (responseData: any) => {

    if (responseData == null) { return } // Checa se retornou algo

    const { contatoRecord: contato = {}, datasusRecord: datasus = {}, historicoTelefonesRecord: historico_telefone = [],
      serasaMapaParentalRecord: mapa_parental = [], serasaHistoricoEmailRecord: historico_email = [], serasaEnderecosRecord: historico_enderecos = [],
      enderecosCepRecord: enderecos_cep = [], serasaMoradoresRecord: serasa_moradores = [], serasaIrpfRecord: serasa_irpf = [],
      posseEmpresasRecord: possee_empresas = [], serasaPoderAquisitivoRecord: poder_aquisitivo = [], serasaScoreRecord: score = [], serasaProfissaoRecord: emprego= [],
      serasaTseRecord: tse = [] } = responseData;

    if (enderecos_cep && enderecos_cep.length >= 5) { setEnderecoCepResultsLimited(true) } // Muitos dados puxados, avisar limite consulta CEP

    const scoreRows = [
      ...score.map(({ CSB8, CSB8_FAIXA, CSBA, CSBA_FAIXA }) =>
      ({
        CSB8: CSB8 || '',
        CSB8_FAIXA: CSB8_FAIXA || '',
        CSBA: CSBA || '',
        CSBA_FAIXA: CSBA_FAIXA || ''
      }))
    ]

    const poderAquisitivoRows = [
      ({
        PoderAquisitivo: poder_aquisitivo.PODER_AQUISITIVO || '',
        Renda: poder_aquisitivo.RENDA_PODER_AQUISITIVO || '',
        FxPoderAquisitivo: poder_aquisitivo.FX_PODER_AQUISITIVO || ''
      })
    ]

    const empregoRows = [
      ({
        Emprego: emprego.DESCRICAO_PROFISSAO || ''
      })
    ]

    const documentosRows = [
      { Documento: "RG", Numero: datasus.rgNumero || '-', Emissao: datasus.rgDataEmissao || '-', OrgaoEmissor: datasus.rgOrgaoEmissor || '-', UF: datasus.rgUf || '-' },
      { Documento: "CNS", Numero: datasus.cns || '-', Emissao: "-", OrgaoEmissor: "-", UF: "-" },
      { Documento: "CPF", Numero: datasus.cpf || '-', Emissao: "-", OrgaoEmissor: "-", UF: "-" }
    ];

    const tituloEleitorRows = [
      { TituloEleitor: tse.TITULO_ELEITOR || '-', Zona: tse.ZONA || '-', Secao: tse.SECAO || '-' }
    ];

    const posseEmpresasRows = [
      ...possee_empresas.map(({ cnpj, data_entrada_sociedade, faixa_etaria, nome_socio, razao_social, capital_social }) =>
      ({
        Cnpj: cnpj || '',
        DataEntrada: data_entrada_sociedade || '',
        FaixaEtaria: faixa_etaria || '',
        NomeSocio: nome_socio || '',
        RazaoSocial: razao_social || '',
        CapitalSocial: capital_social || '-'
      }))
    ]

    const serasaIrpfRows = [
      ...serasa_irpf.map(({ Instituicao_Bancaria, Cod_Agencia, Lote, Ano_Referencia, Dt_Lote, Sit_Receita_Federal, Dt_Consulta }) =>
      ({
        InstituicaoBancaria: Instituicao_Bancaria || '',
        CodAgencia: Cod_Agencia || '',
        Lote: Lote || '',
        AnoRef: Ano_Referencia || '',
        DtLote: Dt_Lote || '',
        SitReceitaFederal: Sit_Receita_Federal || '-',
        DtConsulta: Dt_Consulta || '-'
      }))
    ]

    const enderecoCepRows = [
      ...enderecos_cep.map(({ LOGR_TIPO, LOGR_NOME, LOGR_NUMERO, LOGR_COMPLEMENTO, BAIRRO, CIDADE, UF, CEP }) =>
      ({
        Municipio: (CIDADE || '') + ' ' + (UF || ''),
        Logradouro: (LOGR_TIPO || '') + ' ' + (LOGR_NOME || ''),
        Numero: LOGR_NUMERO || '',
        Bairro: BAIRRO || '',
        CEP: CEP || '',
        Complemento: LOGR_COMPLEMENTO || '-'
      }))
    ];

    enderecoCepRows.sort((a, b) => {
      return a.Numero - b.Numero;
    });

    const emailRows = historico_email.map(({ EMAIL, EMAIL_SCORE }, index) => ({
      id: index || '',
      Email: EMAIL || '',
      Score: EMAIL_SCORE || ''
    }));

    const moradoresRows = Object.keys(serasa_moradores).flatMap((index_CASA) => {
      const moradoresArray = serasa_moradores[index_CASA];
      const uniqueMoradores = {}; // To track unique rows
      return moradoresArray.map((morador) => {
        const { NOME, CPF, SEXO, NASC } = morador;
        const numeroCpfKey = `${index_CASA}-${CPF}`; // Creating a unique key
        if (!uniqueMoradores[numeroCpfKey]) {
          uniqueMoradores[numeroCpfKey] = true;
          return {
            Numero: `${index_CASA}`,
            Nome: NOME || '',
            Cpf: CPF || '',
            Sexo: SEXO || '',
            Nascimento: NASC || ''
          };
        }
        return null; // Returning null for duplicate rows
      }).filter(row => row !== null); // Filtering out null values (duplicates)
    });

    function isCelular(numero: string): boolean {
      const primeiroDigito = parseInt(numero.charAt(0));
      // Verifica se o primeiro dígito está entre 6 e 9 (números móveis)
      return primeiroDigito >= 6 && primeiroDigito <= 9;
    }

    const telefonesRows = historico_telefone.map(({ DDD, TELEFONE, CLASSIFICACAO, DT_INCLUSAO }, index) => ({
      id: index,
      Telefone: `(${DDD || ''}) ${TELEFONE || ''}`,
      Classificacao: CLASSIFICACAO || '',
      Inclusao: DT_INCLUSAO || '',
      IsCelular: isCelular(TELEFONE) ? 1 : 0,
      Celular: TELEFONE
    }));

    const parentesRows = [
      { Nome: contato.NOME_MAE || '-', Vinculo: "MAE" },
      { Nome: contato.NOME_PAI || '-', Vinculo: "PAI" },
      ...mapa_parental.map(({ NOME_VINCULO, VINCULO, CPF_VINCULO }) => ({ Nome: NOME_VINCULO || '', Vinculo: VINCULO || '', CpfVinculo: CPF_VINCULO || '' }))
    ];

    const consultaRows = [
      { Nome: contato.NOME || '', CPF: contato.CPF || '', Sexo: contato.SEXO || '', Nascimento: contato.NASC || '' }
    ];

    const enderecoRows = [
      { Municipio: datasus.municipio || '', Logradouro: datasus.logradouro || '', Numero: datasus.numero || '', Bairro: datasus.bairro || '', CEP: datasus.cep || '' },
      ...historico_enderecos.map(({ LOGR_TIPO, LOGR_NOME, LOGR_NUMERO, LOGR_COMPLEMENTO, BAIRRO, CIDADE, UF, CEP }) =>
        ({ Municipio: CIDADE + ' ' + UF, Logradouro: LOGR_TIPO + ' ' + LOGR_NOME, Numero: LOGR_NUMERO, Bairro: BAIRRO, CEP: CEP }))
    ];

    const filteredScoreRows = scoreRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredPoderAquisitivoRows = poderAquisitivoRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredEmpregoRows = empregoRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredTituloEleitorRows = tituloEleitorRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredPosseEmpresas = posseEmpresasRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredSerasaIrpfRows = serasaIrpfRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredMoradoresRows = moradoresRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredEnderecoCepRows = enderecoCepRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredEmailRows = emailRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredParentesRows = parentesRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredTelefonesRows = telefonesRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredConsultaRows = consultaRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredEnderecoRows = enderecoRows.filter(row => Object.values(row).some(value => value !== ''));
    const filteredDocumentosRows = documentosRows.filter(row => Object.values(row).some(value => value !== ''));

    setScoreRows(filteredScoreRows);
    setPoderAquisitivoRows(filteredPoderAquisitivoRows);
    setTituloEleitorRows(filteredTituloEleitorRows);
    setPosseEmpresaRows(filteredPosseEmpresas);
    setSerasaIrpfRows(filteredSerasaIrpfRows);
    setEnderecoCepRows(filteredEnderecoCepRows);
    setMoradoresRows(filteredMoradoresRows);
    setEmailData(filteredEmailRows);
    setParentesRows(filteredParentesRows);
    setTelefoneRows(filteredTelefonesRows);
    setConsultaRows(filteredConsultaRows);
    setEnderecoRows(filteredEnderecoRows);
    setDocumentosRows(filteredDocumentosRows);
    setEmpregoRows(filteredEmpregoRows);
  }


  const handleVehicleResults = (responseData: any) => {
    setVeiculoData(responseData);
  }

  const { logout } = useAuth();
  const navigate = useNavigate();

  const [tipoConsulta, setTipoConsulta] = React.useState("pessoa");
  const [consultaMae, setConsultaMae] = React.useState(false);
  const [isChoosing, setIsChoosing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [namesList, setMultiResultsList] = React.useState<any[]>([]);
  const handleClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
  const handleOpen = () => setOpen(true);

  const fetchData = async () => {
    setLoading(true);
    const module = searchParams.get('module');
    let data = searchParams.get('data');
    let optional = searchParams.get('optValue');
    optional = optional && optional.length > 0 ? optional : ""
    const bearerToken = localStorage.getItem("token");
    data = (data && module == "TELEFONE") ? data.replace(/\D/g, '') : data
    try {
      const response = await fetch(config.apiUrl + `/busca/dbQueryAPI?usr_data=${data}&module=${module}&optional=${optional}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });
      if (response.status === 401) {
        navigate("/Home");
        logout();
        toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
        openModal();
        return;
      }
      if (response.status === 402) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
          userData.plano.plano = 0;
          localStorage.setItem('userData', JSON.stringify(userData));
        }
        navigate("/Planos");
        toast.error("É preciso assinar um plano para realizar consultas!");
        return;
      }
      if (response.status === 404) {
        navigate("/Home");
        toast.error("Ops! Dados não encontrados.");
        return;
      }
      if (response.status === 403) {
        navigate("/Home");
        alert(`ATENÇÃO: A consulta de informações relacionadas a autoridades do alto escalão do governo e seus familiares é estritamente proibida.

        Qualquer violação dessa política está sujeita a BANIMENTO da conta. 

        Considere-se avisado.`.trim().replace(/\n\s+/g, '\n'));


        toast.error("Ops! Barramento via lista negra.");
        return;
      }
      if (!response.ok) {
        navigate("/Home");
        const errorMessage = await response.text();
        toast.error(errorMessage);
      } else {
        const responseData = await response.json();
        let length = 0;
        switch (module) {
          case "CPF": // pessoa // endereco // veiculo // empresa
            setTipoConsulta("pessoa");
            handleCpfResults(responseData);
            break;
          case "NOME":
            setTipoConsulta("pessoa");
            setConsultaMae(false); // diferencia da consulta nome mae
            length = responseData.length
            if (length > 1) {
              setIsChoosing(true);
              setMultiResultsList(responseData);
              handleOpen();
            } else {
              handleCpfResults(responseData);
            }
            break;
          case "CEP":
            setTipoConsulta("endereco");
            handleCpfResults(responseData);
            break;
          case "CNS":
            setTipoConsulta("pessoa");
            handleCpfResults(responseData);
            break;
          case "CNPJ":
            setTipoConsulta("empresa");
            handleCnpjResults(responseData);
            break;
          case "PLACA":
            setTipoConsulta("veiculo");
            handleVehicleResults(responseData);
            break;
          case "MAE":
            setTipoConsulta("pessoa");
            setConsultaMae(true); // diferencia da consulta nome
            length = responseData.length
            if (length > 1) {
              setIsChoosing(true);
              setMultiResultsList(responseData);
              handleOpen();
            } else {
              handleCpfResults(responseData);
            }
            break;
          case "TELEFONE":
            setTipoConsulta("telefone");
            length = responseData.length
            if (length > 1) {
              setIsChoosing(true);
              setMultiResultsList(responseData);
              handleOpen();
            } else {
              handleCpfResults(responseData);
            }
            break;
          case "FAMILIA":
            setTipoConsulta("FAMILIA");
            handleFamiliaResults(responseData);
            break;
          case "EMAIL":
            setTipoConsulta("pessoa");
            length = responseData.length
            if (length > 1) {
              setIsChoosing(true);
              setMultiResultsList(responseData);
              handleOpen();
            } else {
              handleCpfResults(responseData);
            }
            break;
          case "PIX":
            setTipoConsulta("pessoa");
            handleCpfResults(responseData);
            break;
          case "RG":
            setTipoConsulta("pessoa");
            handleCpfResults(responseData);
            break;
        }
        setLoading(false);
      }
    } catch (e) {
      toast.error("Ops! Ocorreu uma falha de comunicação com o servidor.");
      navigate("/Home");
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [page, setPage] = useState(1);
  // Itens por página
  const itemsPerPage = 5;
  // Função para renderizar os itens da página atual
  const renderItems = () => {
    // Índice do primeiro item na página atual
    const startIndex = (page - 1) * itemsPerPage;
    // Índice do último item na página atual
    const endIndex = startIndex + itemsPerPage;
    // Itens da página atual
    const items = namesList.slice(startIndex, endIndex);

    // Renderiza os itens da página atual
    return (
      <TableContainer style={{ marginTop: "1%", marginBottom: "3%", width: "40vh" }}>
        <Table>
          <TableHead>
            {tipoConsulta == "telefone" ? (
              <TableRow>
                <TableCell align="center">DDD</TableCell>
                <TableCell align="center">Telefone</TableCell>
                <TableCell align="center">Nome</TableCell>
              </TableRow>
            ) : (
              <TableRow>
                {consultaMae ? <TableCell>Mãe</TableCell> : null}
                <TableCell>Nome Filho(a)</TableCell>
                <TableCell>Nascimento</TableCell>
                <TableCell>Cpf</TableCell>
              </TableRow>
            )}
          </TableHead>
          {tipoConsulta == "telefone" ? (
            <TableBody>
              {items.map((item, index) => (
                <TableRow className="hover-row" key={uuidv4()} onClick={() => { handleRowClick(item) }}>
                  <TableCell align="center">{item.DDD}</TableCell>
                  <TableCell align="center">{item.TELEFONE}</TableCell>
                  <TableCell align="center">{item.NOME}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {items.map((item, index) => (
                <TableRow className="hover-row" key={uuidv4()} onClick={() => { handleRowClick(item) }}>
                  {consultaMae ? <TableCell>{item.NOME_MAE}</TableCell> : null}
                  <TableCell>{item.NOME}</TableCell>
                  <TableCell>{formatDate(item.NASC)}</TableCell>
                  <TableCell>{item.CPF}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    );
  };

  const handleRowClick = (row: any) => {
    handleClose(null, "close");
    // if (tipoConsulta == "telefone") {
    //   setSearchParams({ module: "TELEFONE", data: row.TELEFONE, optValue: row.DDD });
    // } else {
    setSearchParams({ module: "CPF", data: row.CPF });
    // }
    setLoading(true);
    setIsChoosing(false);
  }

  // Função para alterar a página
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const uniqueNumerosCasaSet = new Set();

  const [cnpjExpandedItems, setCnpjExpandedItems] = React.useState<any[]>([]);
  const handleExpandCnpj = (itemId) => {
    setCnpjExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((id) => id !== itemId)
        : [...prevExpandedItems, itemId]
    );
  };

  const renderCustomNode = (nodeData) => (
    <g>
      <image
        href={user_pic} // Correctly use the image path
        height="40px"
        width="40px"
        x="-20" // Adjust based on your image size
        y="-20" // Adjust based on your image size
      />
      <text
        x="30" // Adjust based on your layout
        y="5" // Center the text vertically relative to the image
        fill="#FFFFFF"
        fontSize="12"
      >
        {nodeData.name}
      </text>
    </g>
  );

  return (
    <Box>
      {loading ? (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <p style={{ marginBottom: "10px" }}>Carregando...</p>
          <CircularProgress />
        </div>

      ) : isChoosing ?
        (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{
              ...ModalStyle, width: "20%", borderRadius: "2%",
              padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", overflow: "auto"
            }}>
              {/* Faixa preta no topo */}
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "25px",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white"
              }}>
                <IconButton
                  style={{ position: "absolute", left: "5px" }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => { navigate('/home') }}
                >
                  <KeyboardReturnIcon />
                </IconButton>
                <span>Múltiplos Resultados</span>
              </div>
              <div style={{ marginTop: "2%" }}>
                {/* Renderiza os itens da página atual */}
                {renderItems()}
                {/* Renderiza a paginação */}
                <Pagination
                  count={Math.ceil(namesList.length / itemsPerPage)}
                  renderItem={(item) => (
                    <PaginationItem style={{ marginTop: "5%" }}
                      slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                      {...item}
                    />
                  )}
                  page={page}
                  onChange={handleChangePage}
                />
              </div>
            </Box>
          </Modal>
        )

        : (
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Card id="card" ref={reportTemplateRef} style={{ maxHeight: "75vh", width: "90%", margin: "4%", borderRadius: "2%", padding: "1%", overflow: "auto" }} variant="outlined" className="scrollbar-container">
              <style>{`
                /* Custom scrollbar styles */
                .scrollbar-container::-webkit-scrollbar {
                  width: 10px; /* Width of the scrollbar */
                }
                .scrollbar-container::-webkit-scrollbar-track {
                  background: #f1f1f1; /* Color of the track */
                }
                .scrollbar-container::-webkit-scrollbar-thumb {
                  background: #888; /* Color of the scrollbar handle */
                  border-radius: 6px; /* Rounded corners */
                }
                .scrollbar-container::-webkit-scrollbar-thumb:hover {
                  background: #555; /* Color of the scrollbar handle on hover */
                }
               `}</style>
              <Grid container spacing={2}>
                {tipoConsulta == "pessoa" || tipoConsulta == "telefone" ? (
                  <Grid container style={{ position: 'relative', margin: "0.5%" }}>
                    <Grid item style={{ position: 'absolute', top: 15, right: 15 }}>
                      <Button
                        variant="outlined"
                        onClick={downloadPdf}
                        style={{ marginBottom: '1%' }}
                        startIcon={<DownloadIcon />}
                      >
                        Download
                      </Button>
                    </Grid>
                    {consultaRows.map((row: any, index: number) => (
                      <Grid key={uuidv4()} container alignItems="center" style={{ margin: "0.3%" }} spacing={2}>
                        <Grid item>
                          <IconButton sx={{ p: 0 }}>
                            <Avatar alt="" src="" />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <h3 style={{ margin: 0 }}>{row.Nome}</h3>
                          <Typography variant='body2' style={{ margin: 0, opacity: "0.8" }}>Sexo: {row.Sexo == "M" ? "Masculino" : "F" ? "Feminino" : "Indefinido"}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : tipoConsulta == "veiculo" ? (
                  <div style={{ margin: "2%", marginBottom: 0, width: "100%" }}>
                    <Grid item style={{ margin: "0.5%", position: "relative", textAlign: "center" }}>
                      {veiculoData[0]["Placa Nova"] == "f" ? (
                        <img src={img_placa} alt="" style={{ width: "30%" }} />
                      ) : (
                        <img src={img_placa_nova} alt="" style={{ width: "30%" }} />
                      )}
                      <Typography variant="h3" align="center" style={{
                        position: "absolute", fontWeight: "bold",
                        top: "45%", left: "50%", transform: "translate(-50%, -50%)",
                        color: "#282928", whiteSpace: "nowrap"
                      }}>{veiculoData[0]["Placa"]}</Typography>
                      <p>{veiculoData[0]["Marca Modelo"]}</p>
                    </Grid>
                    <Grid item style={{ marginLeft: "auto", marginTop: "5%" }}>
                      <Button
                        variant="outlined"
                        onClick={downloadPdf}
                        style={{ marginBottom: '1%', width: "100%" }}
                        startIcon={<DownloadIcon />}
                      >
                        Download
                      </Button>
                    </Grid>
                  </div>
                ) : tipoConsulta == "empresa" ? (
                  <div style={{ margin: "2%", width: "100%" }}>
                    <Grid item style={{ margin: "0.5%", position: "relative", textAlign: "center" }}>
                      <img src={img_empresa} style={{ filter: 'grayscale(40%)' }} height="220vh" alt="" />
                      <p>{cnpjRows[0]["razao_social"]}</p>
                      <p style={{ fontSize: "12px" }}>{cnpjRows[0]["municipio_"]}</p>
                    </Grid>
                    <Grid item style={{ marginLeft: "auto", marginTop: "5%" }}>
                      <Button
                        variant="outlined"
                        onClick={() => downloadPdf("CNPJ")}
                        style={{ marginBottom: 0, width: "100%" }}
                        startIcon={<DownloadIcon />}
                      >
                        Download
                      </Button>
                    </Grid>
                  </div>
                ) : (<></>)}
              </Grid>
              {tipoConsulta == "pessoa" || tipoConsulta == "telefone" ? (
                <SimpleTreeView style={{ marginTop: "2%" }}
                  defaultExpandedItems={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']}
                  multiSelect={true} selectedItems={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']}>
                  <TreeItem itemId="1" label="Consulta">
                    {consultaRows.length > 0 ? (
                      <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <table id="jspf-print-table" aria-label="Consulta" style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            {consultaRows.map((row: any) => (
                              <React.Fragment key={uuidv4()}>
                                <StyledTableRow>
                                  <td style={{ width: "100px", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.1)", padding: "10px", backgroundColor: "black" }}>Nome</td>
                                  <td style={{ padding: "10px", border: "1px solid rgba(204, 204, 204, 0.1)" }}>{row.Nome}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <td style={{ width: "100px", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.1)", padding: "10px", backgroundColor: "black" }}>Sexo</td>
                                  <td style={{ padding: "10px", border: "1px solid rgba(204, 204, 204, 0.1)" }}>{row.Sexo === 'M' ? 'Masculino' : 'Feminino'}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <td style={{ width: "100px", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.1)", padding: "10px", backgroundColor: "black" }}>Nascimento</td>
                                  <td style={{ padding: "10px", border: "1px solid rgba(204, 204, 204, 0.1)" }}>{formatDate(row.Nascimento)}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <td style={{ width: "100px", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.1)", padding: "10px", backgroundColor: "black" }}>CPF</td>
                                  <td style={{ padding: "10px", border: "1px solid rgba(204, 204, 204, 0.1)" }}>{row.CPF}</td>
                                </StyledTableRow>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="2" label="Endereços" style={{ marginTop: "1%" }}>
                    {enderecoRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Endereços" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Município</StyledTableCell>
                            <StyledTableCell align="right">Logradouro</StyledTableCell>
                            <StyledTableCell align="right">Número</StyledTableCell>
                            <StyledTableCell align="right">Bairro</StyledTableCell>
                            <StyledTableCell align="right">CEP</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {enderecoRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell component="th" scope="row">
                                {row.Municipio}
                              </StyledTableCell>
                              <StyledTableCell align="right">{row.Logradouro}</StyledTableCell>
                              <StyledTableCell align="right">{row.Numero}</StyledTableCell>
                              <StyledTableCell align="right">{row.Bairro}</StyledTableCell>
                              <StyledTableCell align="right">{row.CEP}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="3" label="Documentos" style={{ marginTop: "1%" }}>
                    {documentosRows.length > 0 || tituloEleitorRows.length > 0 ? (
                      <>
                        <Table id="jspf-print-table" aria-label="Documentos" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">Documento</StyledTableCell>
                              <StyledTableCell align="left">Número</StyledTableCell>
                              <StyledTableCell align="left">Emissão</StyledTableCell>
                              <StyledTableCell align="left">Orgão Emissor</StyledTableCell>
                              <StyledTableCell align="left">UF</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {documentosRows.map((row: any) => (
                              <StyledTableRow key={uuidv4()}>
                                <StyledTableCell align="left">{row.Documento}</StyledTableCell>
                                <StyledTableCell align="left">{row.Numero}</StyledTableCell>
                                <StyledTableCell align="left">{row.Emissao}</StyledTableCell>
                                <StyledTableCell align="left">{row.OrgaoEmissor}</StyledTableCell>
                                <StyledTableCell align="left">{row.UF}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Table id="jspf-print-table" aria-label="Título de Eleitor" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">Título Eleitor</StyledTableCell>
                              <StyledTableCell align="left">Zona</StyledTableCell>
                              <StyledTableCell align="left">Seção</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tituloEleitorRows.map((row: any) => (
                              <StyledTableRow key={uuidv4()}>
                                <StyledTableCell align="left">{row.TituloEleitor}</StyledTableCell>
                                <StyledTableCell align="left">{row.Zona}</StyledTableCell>
                                <StyledTableCell align="left">{row.Secao}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="4" label="Telefones" style={{ marginTop: "1%" }}>
                    {telefoneRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Telefones" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Telefone</StyledTableCell>
                            <StyledTableCell align="center">Inclusao</StyledTableCell>
                            <StyledTableCell align="center">Classificacao</StyledTableCell>
                            <StyledTableCell align="center">WhatsApp</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {telefoneRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.Telefone}</StyledTableCell>
                              <StyledTableCell align="center">{formatDate(row.Inclusao)}</StyledTableCell>
                              <StyledTableCell align="center">{row.Classificacao}</StyledTableCell>
                              <StyledTableCell align="center">
                                {row.IsCelular == 1 ?
                                  (
                                    <button
                                      onClick={() => window.open('https://wa.me/' + row.Celular)}
                                      style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                                      <img src={wppImg} height="35vh" alt="Icon" style={{ filter: 'grayscale(90%)' }} />
                                    </button>
                                  ) : (<p>-</p>)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="5" label="Parentes" style={{ marginTop: "1%" }}>
                    {parentesRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Parentes" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Nome</StyledTableCell>
                            <StyledTableCell align="left">CPF</StyledTableCell>
                            <StyledTableCell align="left">Vínculo</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {parentesRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">
                                {
                                  row.CpfVinculo && row.CpfVinculo != ' ' ? (<a href={`/Scan-result?module=CPF&data=${row.CpfVinculo}&optValue=`}>{row.Nome}</a>) : (<>{row.Nome}</>)
                                }
                                </StyledTableCell>
                              <StyledTableCell align="left">{row.CpfVinculo}</StyledTableCell>
                              <StyledTableCell align="left">{row.Vinculo}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="6" label="E-mail" style={{ marginTop: "1%" }}>
                    {emailData.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="E-mail" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">E-mail</StyledTableCell>
                            <StyledTableCell align="left">Score</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {emailData.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.Email}</StyledTableCell>
                              <StyledTableCell align="left">{row.Score}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="7" label="IRPF" style={{ marginTop: "1%" }}>
                    {serasaIrpfRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="IRPF" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Ano Referência</StyledTableCell>
                            <StyledTableCell align="left">Sit. Receita Feteral</StyledTableCell>
                            <StyledTableCell align="left">Data Consulta</StyledTableCell>
                            <StyledTableCell align="left">Instituição Bancária</StyledTableCell>
                            <StyledTableCell align="left">Código Agência</StyledTableCell>
                            <StyledTableCell align="left">Lote</StyledTableCell>
                            <StyledTableCell align="left">Data Lote</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {serasaIrpfRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.AnoRef}</StyledTableCell>
                              <StyledTableCell align="left">{row.SitReceitaFederal}</StyledTableCell>
                              <StyledTableCell align="left">{formatDate(row.DtConsulta)}</StyledTableCell>
                              <StyledTableCell align="left">{row.InstituicaoBancaria}</StyledTableCell>
                              <StyledTableCell align="left">{row.CodAgencia}</StyledTableCell>
                              <StyledTableCell align="left">{row.Lote}</StyledTableCell>
                              <StyledTableCell align="left">{row.DtLote}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="8" label="Empresas" style={{ marginTop: "1%" }}>
                    {posseEmpresaRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Empresas" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Cnpj</StyledTableCell>
                            <StyledTableCell align="left">Data Entrada</StyledTableCell>
                            <StyledTableCell align="left">Faixa Etária</StyledTableCell>
                            <StyledTableCell align="left">Nome Sócio</StyledTableCell>
                            <StyledTableCell align="left">Razão Social</StyledTableCell>
                            <StyledTableCell align="left">Capital Social</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {posseEmpresaRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.Cnpj}</StyledTableCell>
                              <StyledTableCell align="left">{row.DataEntrada}</StyledTableCell>
                              <StyledTableCell align="left">{row.FaixaEtaria}</StyledTableCell>
                              <StyledTableCell align="left">{row.NomeSocio}</StyledTableCell>
                              <StyledTableCell align="left">{row.RazaoSocial}</StyledTableCell>
                              <StyledTableCell align="left">{row.CapitalSocial}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="9" label="Poder Aquisitivo" style={{ marginTop: "1%" }}>
                    {poderAquisitivoRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Poder Aquisitivo" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Poder Aquisitivo</StyledTableCell>
                            <StyledTableCell align="left">Renda</StyledTableCell>
                            <StyledTableCell align="left">Faixa Poder Aquisitivo</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {poderAquisitivoRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.PoderAquisitivo}</StyledTableCell>
                              <StyledTableCell align="left">{row.Renda}</StyledTableCell>
                              <StyledTableCell align="left">{row.FxPoderAquisitivo}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="10" label="Score" style={{ marginTop: "1%" }}>
                    {scoreRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Score" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">CSB8</StyledTableCell>
                            <StyledTableCell align="left">CSB8 FAIXA</StyledTableCell>
                            <StyledTableCell align="left">CSBA</StyledTableCell>
                            <StyledTableCell align="left">CSBA FAIXA</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scoreRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.CSB8}</StyledTableCell>
                              <StyledTableCell align="left">{row.CSB8_FAIXA}</StyledTableCell>
                              <StyledTableCell align="left">{row.CSBA}</StyledTableCell>
                              <StyledTableCell align="left">{row.CSBA_FAIXA}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="11" label="Emprego" style={{ marginTop: "1%" }}>
                  {empregoRows.length > 0 ? (
                      <Table id="jspf-print-table" aria-label="Score" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">EMPREGO</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {empregoRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left">{row.Emprego}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                  <TreeItem itemId="12" label="Mídia Social" style={{ marginTop: "1%" }}>
                    <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                  </TreeItem>
                  <TreeItem itemId="13" label="Veículos" style={{ marginTop: "1%" }}>
                    <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                  </TreeItem>
                </SimpleTreeView>
              ) : tipoConsulta == "veiculo" ? (
                <div>
                  <table id="jspf-print-table" aria-label="Veículo" style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody>
                      <React.Fragment>
                        <StyledTableRow>
                          <td style={{ width: "30%", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.2)", padding: "10px", backgroundColor: "black" }}>*INFORMAÇÕES*</td>
                          <td style={{ width: "30%", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.2)", padding: "10px", backgroundColor: "black" }}>*RESULTADO*</td>
                        </StyledTableRow>
                        {veiculoData.map((row: any) => (
                          Object.entries(row).map(([key, value]: [string, any]) => (
                            <StyledTableRow key={key}>
                              <td style={{ width: "30%", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.2)", padding: "10px" }}>{key}</td>
                              <td style={{ padding: "10px", border: "1px solid rgba(204, 204, 204, 0.2)" }}>{value}</td>
                            </StyledTableRow>
                          ))
                        ))}
                      </React.Fragment>
                    </tbody>
                  </table>
                </div>
              ) : tipoConsulta == "endereco" ? (
                <div style={{ margin: 0 }} >
                  <React.Fragment>
                    {enderecoCepResultsLimited ? (
                      <p style={{ marginTop: "1%", fontSize: "12px", textAlign: 'center', color: "maroon", opacity: "0.95" }}>
                        Atenção: Os resultados dessa consulta foram limitados por questões de desempenho.<br /> Você pode realizar uma nova consulta especificando o número da residência.<p />
                      </p>
                    ) : (
                      <></>
                    )}
                    <TableContainer component={Paper}>
                      <Table id="jspf-print-table" aria-label="Endereços">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align="center">Logradouro</TableCell>
                            <TableCell align="center">Bairro</TableCell>
                            <TableCell align="center">Município</TableCell>
                            <TableCell align="center">Número</TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {enderecoCepRows.map(row => {
                            // Check if the current row.Numero is unique
                            if (!uniqueNumerosCasaSet.has(row.Numero)) {
                              // If it's unique, add it to the set and render the Row component
                              uniqueNumerosCasaSet.add(row.Numero);
                              return <Row key={row.Numero} row={row} moradores={moradoresRows} />;
                            } else {
                              // If it's not unique, do not render the Row component
                              return null;
                            }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                </div>
              ) : tipoConsulta == "empresa" ? (
                <SimpleTreeView style={{ marginTop: 0 }}
                  expandedItems={cnpjExpandedItems}
                  multiSelect={true} selectedItems={['1', '2']}>
                  <TreeItem itemId="1" onClick={() => handleExpandCnpj('1')} label=" Empresa">
                    <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                      <table id="jspf-print-table" aria-label="Empresa" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                          <React.Fragment>
                            <StyledTableRow>
                              <td style={{ width: "30%", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.2)", padding: "10px", backgroundColor: "black" }}>*INFORMAÇÕES*</td>
                              <td style={{ width: "30%", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.2)", padding: "10px", backgroundColor: "black" }}>*RESULTADO*</td>
                            </StyledTableRow>
                            {cnpjRows.map((row: any) => (
                              Object.entries(row).map(([key, value]: [string, any]) => (
                                <StyledTableRow key={key}>
                                  <td style={{ width: "30%", fontWeight: 500, border: "1px solid rgba(204, 204, 204, 0.2)", padding: "10px" }}>{key}</td>
                                  <td style={{ padding: "10px", border: "1px solid rgba(204, 204, 204, 0.2)" }}>{value}</td>
                                </StyledTableRow>
                              ))
                            ))}
                          </React.Fragment>
                        </tbody>
                      </table>
                    </div>
                  </TreeItem>
                  <TreeItem itemId="2" onClick={() => handleExpandCnpj('2')} label=" Sócios" style={{ marginTop: "1%" }}>
                    {cnpjSociosRows ? (
                      <Table id="jspf-print-table" aria-label="Sócios" sx={{ minWidth: 700 }} style={{ marginTop: "2%", marginBottom: "2%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Nome Sócio</StyledTableCell>
                            <StyledTableCell align="left">Identificador Sócio</StyledTableCell>
                            <StyledTableCell align="left">CPF Sócio</StyledTableCell>
                            <StyledTableCell align="left">Qualificação Sócio</StyledTableCell>
                            <StyledTableCell align="left">Data Entrada Sociedade</StyledTableCell>
                            <StyledTableCell align="left">Pais</StyledTableCell>
                            <StyledTableCell align="left">Representante Legal</StyledTableCell>
                            <StyledTableCell align="left">Nome Representante</StyledTableCell>
                            <StyledTableCell align="left">Qualificação Representante Legal</StyledTableCell>
                            <StyledTableCell align="left">Faixa Etária</StyledTableCell>
                            <StyledTableCell align="left">Cnpj Básico</StyledTableCell>
                            <StyledTableCell align="left">Razão Social</StyledTableCell>
                            <StyledTableCell align="left">Natureza Jurídica</StyledTableCell>
                            <StyledTableCell align="left">Qualificação Responsável</StyledTableCell>
                            <StyledTableCell align="left">Porte Empresa</StyledTableCell>
                            <StyledTableCell align="left">Ente Federativo Responsável</StyledTableCell>
                            <StyledTableCell align="left">Capital Social</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cnpjSociosRows.map((row: any) => (
                            <StyledTableRow key={uuidv4()}>
                              <StyledTableCell align="left"><a href={`/Scan-result?module=PIX&data=${row.NomeSocio}&optValue=${row.CnpjCpfSocio.substring(3, 9)}`}>{row.NomeSocio}</a></StyledTableCell>
                              <StyledTableCell align="left">{row.IdentificadorSocio}</StyledTableCell>
                              <StyledTableCell align="left">{row.CnpjCpfSocio}</StyledTableCell>
                              <StyledTableCell align="left">{row.QualificacaoSocio}</StyledTableCell>
                              <StyledTableCell align="left">{row.DataEntradaSociedade}</StyledTableCell>
                              <StyledTableCell align="left">{row.Pais}</StyledTableCell>
                              <StyledTableCell align="left">{row.RepresentanteLegal}</StyledTableCell>
                              <StyledTableCell align="left">{row.NomeRepresentante}</StyledTableCell>
                              <StyledTableCell align="left">{row.QualificacaoRepresentanteLegal}</StyledTableCell>
                              <StyledTableCell align="left">{row.FaixaEtaria}</StyledTableCell>
                              <StyledTableCell align="left">{row.CnpjBasico}</StyledTableCell>
                              <StyledTableCell align="left">{row.RazaoSocial}</StyledTableCell>
                              <StyledTableCell align="left">{row.NaturezaJuridica}</StyledTableCell>
                              <StyledTableCell align="left">{row.QualificacaoResponsavel}</StyledTableCell>
                              <StyledTableCell align="left">{row.PorteEmpresa}</StyledTableCell>
                              <StyledTableCell align="left">{row.EnteFederativoResponsavel}</StyledTableCell>
                              <StyledTableCell align="left">{row.CapitalSocial}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ marginTop: "2%", marginBottom: "2%" }}>Não encontrado</p>
                    )}
                  </TreeItem>
                </SimpleTreeView>
              ) : tipoConsulta == "FAMILIA" ? (
                <div style={{
                  width: "100%",
                  height: "700px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("https://images.unsplash.com/photo-1528183429752-a97d0bf99b5a?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8fA%3D%3D")',
                  backgroundSize: "cover", // Ensures the background image covers the entire container
                  backgroundPosition: "center center", // Centers the background image
                  opacity: "0.8"
                }}>
                   <OrganizationalTree data={treeData} />
                </div>
              ) :
                (<></>)}
            </Card>
          </div>
        )}
    </Box>
  );
}