import cardCpfImage from '../assets/card_cpf.png';
import cardNomeImage from '../assets/card_nome.png';
import cardNA from '../assets/card_na.png'; // Keep for future implementations
import cardPlaceholder from '../assets/card_placeholder.png';
import cardCNS from '../assets/card_cns.png';
import cardVeiculos from '../assets/card_veiculos.png';
import cardMae from '../assets/card_mae.png';
import cardCNPJ from '../assets/card_cnpj.png';
import cardRG from '../assets/card_rg.png';
import cardTelefone from '../assets/card_telefone.png';
import cardCep from '../assets/card_cep.png';
import config from '../configs/config.js';

const data = [
    {
      id: 0,
      title: "Consulta CPF",
      module: "CPF",
      input: "CPF",
      placeholder: "49730226083",
      description: "A consulta por CPF retorna dados relacionados a endereço, números de telefone, parentes, empregos e muito mais.",
      image: config.availableModules.includes("CPF") ? cardCpfImage : cardNA,
      alt: ""
    },

    {
      id: 1,
      title: "Consulta Nome",
      module: "NOME",
      input: "NOME",
      placeholder: "Marcos de Paula Gomes",
      description: "Consulta a partir do nome. Obtenha endereço, números de telefone e muito mais.",
      image: config.availableModules.includes("NOME") ? cardNomeImage : cardNA,
      alt: ""
    },

    {
      id: 2,
      title: "Consulta CNS",
      module: "CNS",
      input: "CNS",
      placeholder: "898004969045013",
      description: "Descubra detalhes usando o CNS. Acesse endereços, parentescos, documentos e mais.",
      image: config.availableModules.includes("CNS") ? cardCNS : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 3,
      title: "Consulta Veículos",
      module: "PLACA",
      input: "PLACA",
      placeholder: "NAH6055",
      description: "Descubra informações cruciais de um veículo apenas digitando a placa.",
      image: config.availableModules.includes("PLACA") ? cardVeiculos : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 4,
      title: "Consulta Telefone",
      module: "TELEFONE",
      input: "TELEFONE",
      placeholder: "91996227489",
      description: "Descubra rapidamente o nome do proprietário, a localização e outros dados relevantes associados ao número.",
      image: config.availableModules.includes("TELEFONE") ? cardTelefone : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 5,
      title: "Consulta CEP",
      module: "CEP",
      input: "CEP",
      placeholder: "40050200",
      description: "Consulte informações relevantes, como localização, moradores, e muito mais, apenas inserindo o número do CEP.",
      image: config.availableModules.includes("CEP") ? cardCep : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 6,
      title: "Consulta CNPJ",
      module: "CNPJ",
      input: "CNPJ",
      placeholder: "42591651000143",
      description: "Descubra informações importantes, como razão social, endereço e situação cadastral, apenas inserindo o número do CNPJ.",
      image: config.availableModules.includes("CNPJ") ? cardCNPJ : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 7,
      title: "Consulta Mãe",
      module: "MAE",
      input: "MAE",
      placeholder: "Maria Jose Gomes",
      description: "Consulte os filhos associados ao nome da mãe de forma rápida e descomplicada.",
      image: config.availableModules.includes("MAE") ? cardMae : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 8,
      title: "Consulta E-mail",
      module: "EMAIL",
      input: "EMAIL",
      placeholder: "teste@hotmail.com",
      description: "Descubra informações importantes como nome, cpf e muito mais, vinculados a um endereço de e-mail.",
      image: config.availableModules.includes("EMAIL") ? 'https://img.freepik.com/premium-photo/businessman-holding-checking-email-online-with-virtual-interface-technology-direct-marketing-online-message-email-electronic-mail-communication-concept_35148-5018.jpg' : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 9,
      title: "Consulta PIX",
      module: "PIX",
      input: "NOME",
      placeholder: "Maria Jose Gomes",
      description: "Descubra informações relacionadas ao pagador de um PIX.",
      image: config.availableModules.includes("PIX") ? 'https://www.tupi.fm/wp-content/uploads/2023/01/o-que-e-o-pix-1536x1002-1.png' : cardNA,
      alt: "Imagem função indisponível no momento."
    },

    {
      id: 10,
      title: "Consulta RG",
      module: "RG",
      input: "RG",
      placeholder: "20145858",
      description: "Descubra informações importantes como nome, endereço, parentes e muito mais, utilizando apenas um número de RG.",
      image: config.availableModules.includes("RG") ? cardRG : cardNA,
      alt: "Imagem função indisponível no momento."
    }
  ];

  export default data;
  