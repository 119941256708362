"use client";

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import { Link } from 'react-router-dom';
import { useAuth } from './authentication/authContext.tsx';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/icon.png'

interface ResponsiveAppBarProps {
  openModal: () => void;
  swapTab: (value: string) => void;
}

function ResponsiveAppBar({ openModal, swapTab }: ResponsiveAppBarProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const { isLoggedIn, logout, userData } = useAuth();

  let pages;
  if (isLoggedIn) {
    pages = ['Home', 'Sobre', 'Termos', /*'Planos',*/ 'Contato'];
  } else {
    pages = ['Home', 'Sobre'/*, 'Planos'*/];
  }

  const [selectedPage, setSelectedPage] = React.useState(pages[0]); // Inicialmente selecionado como a primeira página

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onProfileClick = () => {
    navigate(`/Perfil`);
    //navigate(`/Fake`);
  };

  return (
    <AppBar position="static">
      <Container style={{maxWidth: "100%"}}>
        <Toolbar disableGutters style={{width: "100%"}}>
          <img src={logo} alt="" height="40px" style={{marginRight: "0.5%"}} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            LIFE-SCAN
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Link to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {page}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <SettingsInputAntennaIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LIFE-SCAN
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link
                style={{
                  textDecoration: 'none', color: selectedPage === page ? 'rgba(255, 255, 255, 0.7)' : 'white', display: 'block',
                  backgroundColor: selectedPage === page ? 'rgba(171, 219, 227, 0.03)' : 'rgba(0, 0, 0, 0.00)'
                }} key={page} to={`/${page.toLowerCase()}`}>
                <Button
                  onClick={() => setSelectedPage(page)} // Define a página selecionada ao clicar no botão
                  style={{ textDecoration: 'none', color: "white" }}>
                  {page}
                </Button>
              </Link>
            ))}
          </Box>

          {isLoggedIn ? (
            <Box sx={{ flexGrow: 0, mr: "2%" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <div style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "gray",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    color: "black"
                  }}>
                    {userData?.usuario.charAt(0).toUpperCase()}
                  </div>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="Perfil" onClick={() => { handleCloseUserMenu(); onProfileClick(); }}>
                  <Typography textAlign="center">Perfil</Typography>
                </MenuItem>
                <MenuItem key="Logout" onClick={() => { handleCloseUserMenu(); logout(); navigate("/Home"); }}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0, mr: 0 }}>
              <Button variant="text" onClick={() => { openModal(); swapTab("1"); }}>Entrar</Button>-<Button variant="text" onClick={() => { openModal(); swapTab("2"); }}>Cadastrar</Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;