import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';

export default function Contato() {

  const [inputText, setInputText] = React.useState('');

  // Função para lidar com a cópia do texto para a área de transferência
  const handleCopyText = () => {
    
  };

  return (
    <Box style={{ display: "flex", maxHeight: "90%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Card style={{ width: "80%", margin: "4%", overflow: "auto", borderRadius: "2%", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} variant="outlined">
        <EmailIcon style={{ fontSize: "300%", marginTop: "2%", marginBottom: 0 }}></EmailIcon>
        <h4>Em caso de dúvidas ou sugestões, entre em contato via e-mail: 
          <a href='mailto:life-scan.support@proton.me?subject=LIFE-SCAN SUPPORT - HELP - SUGGESTIONS'
        style={{ color: '#90CAF9', display: "inline", textDecoration: "None" }}> life-scan.support@proton.me</a></h4>
        <p style={{marginTop: 0, opacity: "0.2"}}>Demonstramos grande generosidade ao recompensar colaborações.</p>
      </Card>
    </Box>
  );
}