import config from '../configs/config'
import { toast } from 'react-toastify';

export const cadastro = async (cadastroFormData: any, CaptchaId: any, CaptchaText: any) => {

    let cadastroSuccess: boolean = false;
    try {
      const response = await fetch(config.apiUrl + `/Cadastro?CaptchaId=${CaptchaId}&CaptchaText=${CaptchaText}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cadastroFormData)
      });
      if (!response.ok) {
        const errorMsg = await response.text();
        toast.error(errorMsg);
      } else {
        const data = await response.json(); // Parse the JSON response
        const token = data.token;
        localStorage.setItem('RecoveryToken', token);
        cadastroSuccess = true;
        toast.success('Usuário cadastrado com sucesso!');
        //handleClose();
        //const data = await response.json();
      }
    } catch (error) {
      toast.error("Ops! Erro ao realizar cadastro. Por favor tente novamente mais tarde.");
    }
    return cadastroSuccess;
  }