import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/authContext.tsx';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./profilePage.css"
import { UserData } from '../../interfaces/userDataInterface.ts';
import config from '../../configs/config.js';
import { getInfoPlanos } from '../../services/planoService.ts'
import Modal from '@mui/material/Modal';
import QRcode from '../../assets/QRcode.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000000', // Use your desired background color
    color: '#ffffff', // White color for text
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#272929',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "35vh",
  height: '60%',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: "white",
  overflow: "hidden"
};

const terms = config.terms;

const imgStyle = {
  '--s': '50px',
  '--t': '5px',
  '--g': '20px',
  padding: 'calc(var(--g) + var(--t))',
  outline: 'var(--t) solid #94928e',
  outlineOffset: 'calc(-1 * var(--t))',
  mask: `conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 / calc(100% - var(--s)) calc(100% - var(--s)), linear-gradient(#000 0 0) content-box`,
  transition: '.4s'
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Perfil() {
  const [value, setValue] = React.useState(0);
  const [userData, setUserData] = React.useState<UserData | null>(null);

  const { trocaSenha } = useAuth();

  const [changePasswordFormData, setChangePasswordFormData] = React.useState({
    senhaAtual: '',
    novaSenha: '',
    confirmarNovaSenha: ''
  });

  const [changingPassword, setChangingPassword] = React.useState(0);
  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    if (senhaError) {
      toast.error(senhaErrorText);
      return;
    }
    toast.promise(
      new Promise(async (resolve, reject) => {
        let trocaSenhaResult: boolean;
        setChangingPassword(1);
        trocaSenhaResult = await trocaSenha(changePasswordFormData);
        setChangingPassword(0);
        if (trocaSenhaResult) {
          resolve("");
        } else {
          reject();
        }
      }),
      {
        pending: "Alterando senha..."
      }
    );
  }

  const [isSenhaDirty, setSenhaDirty] = React.useState(false);
  const [isConfirmaSenhaDirty, setConfirmaSenhaDirty] = React.useState(false);

  const handleChangePasswordFieldsChange = (fieldName: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (fieldName) {
      case "novaSenha":
        setSenhaDirty(true);
        break;
      case "confirmarNovaSenha":
        setConfirmaSenhaDirty(true);
        break;
    }
    await new Promise<void>(resolve => {
      setChangePasswordFormData(prevState => {
        const newState = { ...prevState, [fieldName]: event.target.value };
        validaSenha(newState, fieldName);
        return newState;
      });
      resolve();
    });
  };

  const [senhaErrorText, setSenhaErrorText] = React.useState("");
  const [senhaError, setSenhaError] = React.useState(false);

  const validaSenha = (newState: any, field: string) => {
    let senha = field == "novaSenha" ? newState.novaSenha : newState.confirmarNovaSenha;
    setSenhaError(false);
    setSenhaErrorText("");

    // Verifica se o comprimento da senha está entre 8 e 64 caracteres
    if (senha.length < 8 || senha.length > 64) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter no mínimo 8 e no máximo 64 caracteres.");
      return;
    }

    // Verifica se a senha contém pelo menos uma letra minúscula
    if (!/[a-z]/.test(senha)) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter pelo menos uma letra minúscula.");
      return;
    }

    // Verifica se a senha contém pelo menos uma letra maiúscula
    if (!/[A-Z]/.test(senha)) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter pelo menos uma letra maiúscula.");
      return;
    }

    // Verifica se a senha contém pelo menos um número
    if (!/\d/.test(senha)) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter pelo menos um número.");
      return;
    }

    if ((isSenhaDirty && isConfirmaSenhaDirty) && (newState.novaSenha != newState.confirmarNovaSenha)) {
      setSenhaError(true);
      setSenhaErrorText("As senhas não coincidem.");
      return;
    }
  };

  const [planosInfo, setPlanosInfo] = React.useState("");
  const [recoveryToken, setRecoveryToken] = React.useState("");
  const [paymentHistory, setPaymentHistory] = React.useState([{}]);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
    const fetchPlanoData = async () => {
      try {
        const result = await getInfoPlanos();
        if (result) { setPlanosInfo(result); }
      } catch (error) {
        // Handle errors
        toast.error("Ops! Falha de comunicação com o servidor.")
      }
    };
    fetchPlanoData();
    const fetchRecToken = async () => {
      const bearerToken = localStorage.getItem("token");
      try {
        const response = await fetch(config.apiUrl + '/getUserData/getRecoveryToken', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        });
        if (response.status === 401) {
          navigate("/Home");
          logout();
          toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
          //openModal();
          return;
        }
        if (!response.ok) {
          const errorMessage = await response.text();
          toast.error(errorMessage);
        } else {
          const responseData = await response.json();
          setRecoveryToken(responseData.token);
        }
      } catch (e) {
        toast.error("Ops! Falha de comunicação com o servidor.");
      }
    };
    fetchRecToken();
    const fetchPaymentHistory = async () => {
      const bearerToken = localStorage.getItem("token");
      try {
        const response = await fetch(config.apiUrl + `/Payment/PaymentHistory?uid=${JSON.parse(storedUserData).id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        });
        if (response.status === 401) {
          navigate("/Home");
          logout();
          toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
          //openModal();
          return;
        }
        if (!response.ok) {
          const errorMessage = await response.text();
          toast.error(errorMessage);
        } else {
          const responseData = await response.json();
          setPaymentHistory(responseData.pagamentos);
        }
      } catch (e) {
        toast.error("Ops! Falha de comunicação com o servidor.");
      }
    };
    fetchPaymentHistory();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [subAddTime, setSubAddTime] = React.useState("");
  const handleSubSelectChange = (event: SelectChangeEvent) => {
    if (event.target.value !== null) {
      setSubAddTime(event.target.value as string);
    }
  };

  // Função para formatar a data como uma string legível no formato DD/MM/YYYY
  const formatDate = (dateString: string | undefined) => {
    if (dateString == undefined || dateString.length == 0)
      return 'ERROR';

    const date = new Date(dateString);

    // Verifica se a conversão foi bem-sucedida e retorna a data formatada
    if (!isNaN(date.getTime())) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Adiciona um zero à esquerda se o dia ou mês tiver apenas um dígito
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;

      return `${formattedDay}/${formattedMonth}/${year}`;
    } else {
      // Se a conversão falhar, retorna uma string vazia ou um valor padrão
      return ""; // ou qualquer valor padrão que você deseja retornar em caso de data inválida
    }
  };

  function ReadOnlyTextFieldWithVisibility(props: any) {
    const [showText, setShowText] = React.useState(false);

    const handleToggleVisibility = () => {
      setShowText(!showText);
    };

    return (
      <TextField
        style={{ marginTop: "5%", width: "160%" }}
        disabled
        readonly
        {...props}
        type={showText ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleVisibility} edge="end">
                {showText ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const [isAddingSubAddTime, setAddingSubAddTime] = React.useState(0);
  const handleExtendePlano = async () => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        let success: boolean;
        setAddingSubAddTime(1);
        success = await extendePlano();
        setAddingSubAddTime(0);
        if (success) {
          resolve("");
        } else {
          reject();
        }
      }),
      {
        pending: "Aguarde um momento enquanto estendemos o seu plano..."
      }
    );
  };

  const { logout } = useAuth();
  const navigate = useNavigate();

  const extendePlano = async () => {
    let success: boolean = false;
    const bearerToken = localStorage.getItem("token");

    if (bearerToken == null || userData == null) {
      toast("Erro: Por favor efetue um novo login.");
    } else {
      try {
        const response = await fetch(config.apiUrl + '/subscription/addTime', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uid: userData.id,
            TimeOption: subAddTime
          })
        });
        if (response.status === 401) {
          navigate("/Home");
          logout();
          toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
          return false;
        }
        if (!response.ok) {
          const errorMessage = await response.text();
          toast.error(errorMessage);
        } else {
          const data = await response.json();
          userData.plano = data;
          localStorage.setItem('userData', JSON.stringify(userData));
          success = true;
          handleCloseModal(null, "close");
          toast('💗 Seu plano foi extendido com sucesso! \n Agradecemos pelo suporte.', {
            autoClose: 15000
          });
        }
      } catch (error) {
        //console.error('Erro ao estender plano:', error);
        toast.error("Ops! Falha ao estender plano. Contacte um administrador ou tente novamente mais tarde.");
      }
    }
    return success;
  }

  const addTime = (dateString: string | undefined) => {
    if (dateString == undefined)
      return '';

    const currentDate = new Date(dateString)

    switch (subAddTime) {
      case "0":
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case "1":
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case "2":
        currentDate.setMonth(currentDate.getMonth() + 2);
        break;
      case "3":
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case "4":
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
    }
    return `${currentDate}`;
  }

  const [open, setOpen] = React.useState(false);
  const handleCloseModal = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
  const handleOpenModal = () => {
    setCaptchaValue('invalid');
    setTermosDeUsoAceitos(false);
    setOpen(true);
  }

  const [pageIndex, setPageIndex] = React.useState(false);
  const [termosDeUsoAceitos, setTermosDeUsoAceitos] = React.useState(false);
  const [creatingPayment, setCreatingPayment] = React.useState(false);
  const [qrCode, setQrCode] = React.useState('');
  const [qrCodeBase64, setQrCodeBase64] = React.useState('');
  //const [cancelPayment, setCancelPayment] = React.useState(false);
  const [waitingForPayment, setWaitingForPayment] = React.useState(false);

  const [captchaValue, setCaptchaValue] = React.useState('invalid');
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const cancelaPagamento = () => {
    const confirmation = window.confirm("Tem certeza de que deseja cancelar o pagamento?");

    if (confirmation) {
      //setCancelPayment(true);
      handleCloseModal(null, "close");
    }
  }

  const handleCheckboxChange = (event) => {
    setTermosDeUsoAceitos(event.target.checked);
  };

  const [precoPlanoSelecionado, setPrecoPlanoSelecionado] = React.useState("");
  const recebePagamento = () => {
    setPrecoPlanoSelecionado(getPrecoPlano());
    handleOpenModal();
  }

  const handleCopyToClipboard = () => {
    const textToCopy = '7F9A3B2E1D5C6A8F7E2B9C3D6A4B1E5'; // Texto que será copiado
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        toast.info("Código PIX copiado.");
      })
      .catch(err => {
        //console.error('Erro ao copiar o texto:', err);
      });
  };

  const getPrecoPlano = () => {

    let preco = "N/A";
    let plano;
    try {
      plano = JSON.parse(planosInfo);
    } catch (error) {
      // Handle the parsing error
      console.error('Error parsing JSON:', error);
      return " 'SERVER ERROR!'"
    }
    let aux = 0;

    switch (subAddTime) {
      case "0": // 7 dias
        plano = plano.find(item => item.id === 2);
        preco = plano.preco.toFixed(2).toString().replace(/\./g, ',');
        break;
      case "1": // 30 dias
        plano = plano.find(item => item.id === 3);
        preco = plano.preco.toFixed(2).toString().replace(/\./g, ',');
        break;
      case "2": // 60 dias
        plano = plano.find(item => item.id === 3);
        aux = Number(plano.preco) * 2; // 2 meses
        preco = aux.toFixed(2).toString().replace(/\./g, ',');
        break;
      case "3": // 90 dias
        plano = plano.find(item => item.id === 3);
        aux = Number(plano.preco) * 3; // 3 meses
        preco = aux.toFixed(2).toString().replace(/\./g, ',');
        break;
      case "4": // 365 dias
        plano = plano.find(item => item.id === 3);
        aux = Number(plano.preco) * 12; // 12 meses
        preco = aux.toFixed(2).toString().replace(/\./g, ',');
        break;
    }

    return preco;
  }

  const getPrazoSelecionado = () => {
    let prazo = "";
    switch (subAddTime) {
      case "0": // 7 dias
        prazo = "7"
        break;
      case "1": // 30 dias
        prazo = "30"
        break;
      case "2": // 60 dias
        prazo = "60"
        break;
      case "3": // 90 dias
        prazo = "90"
        break;
      case "4": // 365 dias
        prazo = "365"
        break;
    }
    return prazo;
  }

  return (
    <Box style={{ display: "flex", maxHeight: "90%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Card style={{ width: "80%", margin: "4%", overflow: "auto", borderRadius: "2%", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} variant="outlined">
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{
            ...ModalStyle,
            backgroundColor: "rgba(89, 89, 88, 1)",
            width: "20%",
            borderRadius: "2%",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}>
            {/* Faixa no topo */}
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "25px",
              backgroundColor: "gray",
              textAlign: "center",
              color: "white"
            }}>
              Pagamento - PIX
            </div>
            {pageIndex == true && creatingPayment == false ? (
              <div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}>
                  {qrCodeBase64 ? (
                    <img src={`data:image/png;base64, ${qrCodeBase64}`} width="50%" alt="Descrição da imagem" style={imgStyle} />
                  ) : (
                    <p>Carregando QR code...</p>
                  )}
                </div>
                <hr style={{ width: "90%", marginTop: "10%", opacity: "0.5" }} />
                <div style={{ textAlign: 'center' }}>
                  <h4 style={{ marginTop: '1%', marginBottom: '1%', color: 'white' }}>Copia e cola:
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input
                        disabled
                        id="textInput"
                        type="text"
                        value={qrCode}
                        style={{ marginRight: '10px', marginTop: "1%" }}
                      />
                      <button onClick={handleCopyToClipboard}>Copiar</button>
                    </div>
                  </h4>
                </div>
                <hr style={{ width: "90%", marginTop: "1%", opacity: "0.5" }} />
                <h4 style={{ marginTop: "1%", marginBottom: "1%", color: "white", textAlign: "center" }}>Usuário: {userData?.usuario}</h4>
                <hr style={{ width: "90%", marginTop: 0, opacity: "0.5" }} />
                <h4 style={{ marginTop: "1%", marginBottom: "1%", color: "white", textAlign: "center" }}>Prazo selecionado: +X DIAS</h4>
                <hr style={{ width: "90%", marginTop: 0, opacity: "0.5" }} />
                <h4 style={{ marginTop: "1%", marginBottom: "1%", color: "white", textAlign: "center" }}>Preço: R${precoPlanoSelecionado}</h4>
                <hr style={{ width: "90%", marginTop: 0, opacity: "0.5" }} />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {waitingForPayment == true ? (
                    <div>                      
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ marginTop: "1%", marginRight: "10px" }}>Aguardando Pagamento</Button>
                        <CircularProgress />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ marginTop: "2%", color: "red", marginRight: "10px" }} onClick={cancelaPagamento}>Cancelar</Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Button style={{ marginTop: "2%", color: "red", marginRight: "10px" }} onClick={() => { handleCloseModal(null, "close") }}>Fechar</Button>
                      <Button style={{ marginTop: "1%", color: "green", marginRight: "10px" }}>PAGAMENTO APROVADO</Button>
                    </>
                  )}
                </div>
                <p style={{ fontSize: "11px", color: "red", opacity: "0.9", margin: 0, marginTop: "3%", textAlign: "center" }}>PIX é o único método de pagamento aceito atualmente.</p>
                <p style={{ fontSize: "11px", color: "red", opacity: "0.9", margin: 0, marginBottom: "1%", textAlign: "center" }}>Não ofertamos reembolso.</p>
              </div>

            ) : (
              <div>
                {creatingPayment == true ?
                  (
                    <CircularProgress />
                  ) :
                  (
                    <div>
                      <textarea
                        value={terms}
                        readOnly
                        rows={10} // Define o número de linhas
                        cols={50} // Define o número de colunas
                        style={{
                          resize: 'none',
                          width: '95%',
                          minHeight: '300px',
                          backgroundColor: '#222', // Dark background color
                          color: '#fff', // White text color
                          padding: "1%",
                          border: 'none' // Remove border (optional)
                        }}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox color="secondary" checked={termosDeUsoAceitos} onChange={handleCheckboxChange} />
                        <Typography variant="body2">
                          Li e concordo com os termos e condições de uso
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}>
                        <ReCAPTCHA
                          className="g-recaptcha"
                          sitekey="6LcNDvUpAAAAAH3EGJkU-aFbBOSnEiP3EvG-c23w"
                          theme="dark"
                          size="normal"
                          onChange={handleCaptchaChange}
                        />
                        <Button style={{ marginTop: "5%" }}>Gerar Pagamento via PIX</Button>
                        <Button style={{ marginTop: "2%", color: "red" }} onClick={cancelaPagamento}>Cancelar</Button>
                      </div>
                    </div>
                  )}
              </div>

            )}

          </Box>
        </Modal>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} style={{ height: "90%" }}>
            <Card style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{
                width: "140px",
                height: "140px",
                backgroundColor: "gray",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "40px",
                color: "black",
                marginTop: "10%"
              }}>
                {userData?.usuario.charAt(0).toUpperCase()}
              </div>
              <h5 style={{ marginTop: "5%" }}>{userData?.usuario}</h5>
              <hr style={{ width: "90%", marginTop: "15%", opacity: "0.5" }} />
              <h4 style={{ marginTop: "5%", marginBottom: "5%" }}>
                PLANO: {userData?.plano.plano != 0 ? (<span className="active" style={{ display: "inline" }}>Ativo</span>) :
                  (<span className="inactive" style={{ display: "inline" }}>ATIVO</span>)}</h4>
              <hr style={{ width: "90%", opacity: "0.5", marginBottom: "5%" }} />
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card style={{ height: "50vh" }}>
              <Box
                sx={{ flexGrow: 1, display: 'flex', height: "100%", overflow: "auto" }}
              >
                <Tabs style={{ marginTop: "2%" }}
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleTabChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  <Tab style={{display: "none"}} label="Meu Plano" {...a11yProps(0)} />
                  <Tab label="Websites Alternativos" {...a11yProps(1)} />
                  <Tab label="Minha Conta" {...a11yProps(2)} />
                  {userData?.plano.plano != 0 ? (
                    <Tab label="Suporte" {...a11yProps(3)} />
                  ) : (<></>)}
                </Tabs>
                <TabPanel value={value} index={100}>
                  <h2 style={{ marginTop: "5%", marginBottom: "5%" }}>Detalhes do seu Plano</h2>
                  {userData?.plano.plano != 0 ? (
                    <div>
                      <h5>
                        Parabéns! Seu plano{' '}
                        <Typography variant='body2' sx={{ color: 'primary.main', display: "inline" }}>
                          {userData?.plano.plano == 1 ? "Basic" : userData?.plano.plano == 2 ? "Standard" : "Professional"}
                        </Typography>{' '}
                        está ativo, e expira em {formatDate(userData?.plano.data_expira)}
                      </h5>
                    </div>
                  ) : (
                    <div>
                      <h5>
                        Você ainda não possui plano ativo.{' '}
                        <Link to="/Planos">Escolha um plano</Link> e realize consultas ilimitadas!
                      </h5>
                    </div>

                  )}

                  <SimpleTreeView style={{ marginTop: "5%" }}>
                    {userData?.plano.plano != 0 ? (
                      <TreeItem itemId="grid" label="Estender Plano"> {/* TODO -> ADICIONAR FUTURAMENTE */}
                        <FormControl fullWidth style={{ marginTop: "4%" }}>
                          <InputLabel id="demo-simple-select-label">Prazo</InputLabel>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5}>
                              <Select
                                style={{ width: "100%" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={subAddTime}
                                onChange={handleSubSelectChange}
                                label="Age"
                              >
                                <MenuItem value="0">+7 dias</MenuItem>
                                <MenuItem value="1">+30 dias</MenuItem>
                                <MenuItem value="2">+60 dias</MenuItem>
                                <MenuItem value="3">+90 dias</MenuItem>
                                <MenuItem value="4">+365 dias</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={4}>
                              <Button onClick={ () => {alert("Esta função está indisponível no momento. Por favor aguarde atualizações.")}} disabled={subAddTime == "" || isAddingSubAddTime == 1 ? true : false} variant="contained" style={{ height: "5vh" }} fullWidth>Adicionar</Button>
                            </Grid>
                          </Grid>
                        </FormControl>
                        {subAddTime != "" ? (
                          <Paper variant="outlined" sx={{
                            marginTop: "1%", display: 'flex', alignItems: 'center', padding: '8px',
                            borderColor: 'gray', backgroundColor: 'transparent', width: "70%"
                          }}>
                            <InfoIcon sx={{ marginRight: '2%' }} />
                            <Typography variant="body2">
                              Atenção: Seu plano expira em {formatDate(userData?.plano.data_expira)}, e passará a expirar em {formatDate(addTime(userData?.plano.data_expira))}.
                              O valor cobrado será:
                              <p style={{ color: "#8df0a8", display: "inline" }}> R${getPrecoPlano()}</p>
                            </Typography>
                          </Paper>
                        ) : (<></>)}
                      </TreeItem>
                    ) :
                      (<></>)}
                    <TreeItem itemId="compras" label="Histórico de Compras" style={{ marginTop: "5%" }}>
                      {paymentHistory.length > 0 ? (
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" style={{ marginTop: "2%", marginBottom: "2%" }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="right">ID - Transação</StyledTableCell>
                              <StyledTableCell align="right">Data</StyledTableCell>
                              <StyledTableCell align="right">Plano</StyledTableCell>
                              <StyledTableCell align="right">Status</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paymentHistory.map((row: any) => (
                              <StyledTableRow key={uuidv4()}>
                                <StyledTableCell align="right">{row.tid}</StyledTableCell>
                                <StyledTableCell align="right">{formatDate(row.date)}</StyledTableCell>
                                <StyledTableCell align="right">{row.plano == 1 ? 'Basic' : row.plano == 2 ? 'Satandard' : 'Professional'}</StyledTableCell>
                                <StyledTableCell align="right" style={{ color: row.status === "Approved" ? 'green' : 'red' }}>{row.status}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <p>Nenhum dado de compra encontrado.</p>
                      )}
                    </TreeItem>
                  </SimpleTreeView>

                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div>
                    <h2 style={{ marginTop: "5%", marginBottom: "2%" }}>Websites Alternativos (URL)</h2>
                    <p style={{ maxWidth: "90%" }}>
                      Acesse nosso site em: <a href="https://life-scan.info">https://life-scan.info</a>.
                    </p>
                    <p style={{ maxWidth: "90%" }}>
                      Acesse pela Deep Web: <a href="">http://www.qqbvqqhnwip4r24hohdyinsj6mry6m7zy7pmrvuhpkkjuwbdm7ut5zqd.onion/</a>.
                    </p>
                    <p style={{color:"red"}}>Atenção: Guarde a URL Deep Web. Você poderá acessá-la utilizando o navegador TOR caso nosso site saia do ar. Obrigado!</p>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <h2 style={{ marginTop: "5%", marginBottom: "5%" }}>Detalhes da Conta</h2>
                  <SimpleTreeView>
                    <TreeItem itemId="grid" label="Alterar Senha">
                      <form onSubmit={handleChangePassword}>
                        <TextField
                          onChange={handleChangePasswordFieldsChange('senhaAtual')}
                          value={changePasswordFormData.senhaAtual}
                          style={{ width: "100%", marginTop: "2%" }}
                          required
                          id="outlined-required"
                          label="Senha Atual"
                          variant="outlined"
                          placeholder="Senha Atual"
                          type="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }} />
                        <TextField
                          error={senhaError && isSenhaDirty}
                          helperText={isSenhaDirty ? senhaErrorText : ""}
                          onChange={handleChangePasswordFieldsChange('novaSenha')}
                          value={changePasswordFormData.novaSenha}
                          style={{ width: "100%", marginTop: "2%" }}
                          required
                          id="outlined-required"
                          label="Nova Senha"
                          variant="outlined"
                          placeholder="Nova Senha"
                          type="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }} />
                        <TextField
                          error={senhaError && isConfirmaSenhaDirty}
                          helperText={isConfirmaSenhaDirty ? senhaErrorText : ""}
                          onChange={handleChangePasswordFieldsChange('confirmarNovaSenha')}
                          value={changePasswordFormData.confirmarNovaSenha}
                          style={{ width: "100%", marginTop: "2%" }}
                          required
                          id="outlined-required"
                          label="Confirmar Nova Senha"
                          variant="outlined"
                          placeholder="Confirmar Nova Senha"
                          type="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }} />
                        <Button type="submit" variant="contained" disabled={changingPassword === 1} style={{ marginTop: "1%", width: "100%", height: "4vh" }}>Alterar Senha</Button>
                      </form>
                    </TreeItem>
                  </SimpleTreeView>
                  <SimpleTreeView style={{ marginTop: "5%" }}>
                    <TreeItem itemId="grid" label="Token de Recuperação">
                      <ReadOnlyTextFieldWithVisibility
                        value={recoveryToken}
                        variant="standard"
                        InputProps={{ readOnly: true }}
                      />
                    </TreeItem>
                  </SimpleTreeView>
                </TabPanel>
                {userData?.plano.plano != 0 ? (
                  <TabPanel value={value} index={3}>
                    <h2 style={{ marginTop: "5%", marginBottom: "5%" }}>Suporte</h2>
                    Contato de suporte para assinantes:
                    <a href='mailto:life-scan.support@proton.me?subject=LIFE-SCAN SUPPORT VIP - SUBSCRIBERS&body=Username do assinante: [SEU USUÁRIO]'
                      style={{ color: '#90CAF9', display: "inline", textDecoration: "None" }}> life-scan.support@proton.me</a>
                    <p style={{ fontSize: "12px", marginTop: "1%", opacity: "0.7" }}>Para atendimento preferencial favor identificar-se com nome de usuário.</p>
                  </TabPanel>
                ) : (<></>)}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}