import logo from './logo.svg';
import './App.css';
import { createTheme, Paper, ThemeProvider } from '@mui/material';
import { ToastContainer, ToastPosition, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import {AuthProvider} from './components/authentication/authContext.tsx';
import MainComponent from './components/main.tsx';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const theme = createTheme({
  palette: {
    mode: "dark"
  },
});

const toastContainerOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
  transition: Bounce,
};

function App() {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Paper style={{ height: "100vh", overflow: "auto" }}>
          <MainComponent></MainComponent>
          <ToastContainer {...toastContainerOptions} />
        </Paper>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
