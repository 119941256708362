import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import data from '../../data/menu_data.ts'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fingerprint from '@mui/icons-material/Fingerprint';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/authContext.tsx';
import { toast } from 'react-toastify';
import Footer from '../footer.tsx';
import defaultImage from '../../assets/card_placeholder.png';
import config from '../../configs/config.js';
import './homePage.css'
import { useEffect } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Modalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 240,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: "white"
};

const cardStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "white",
  height: "30vh",
  filter: "grayscale(70%)"
};

export default function MainCard() {

  useEffect(() => {
    try {
      // check if token still valid
      fetch(config.apiUrl + `/Alert/getAlert`, {
        method: 'GET'
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.id != 10) {
            setIsAlertVisible(true);
            setAlertType(data.id);
            setAlertMessage(data.text);
            setAlertPreText(data.pretext);
          }
        })
        .catch(error => { });
    } catch (error) { }
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [inputValue, setInputValue] = React.useState('');
  const [optionalInputValue, setOptionalInputValue] = React.useState('');

  const [selectedCardData, setSelectedData] = React.useState(null);

  const { isLoggedIn } = useAuth();

  const navigate = useNavigate();

  const onCardClick = (data: any) => {

    if (!isLoggedIn) {
      toast.error("É preciso estar cadastrado para realizar consultas.");
      return;
    }

    if (!(data.module && config.availableModules.includes(data.module))) {
      toast.error("Ops! Módulo indisponível no momento.");
      return;
    }

    setSelectedData(data);
    setSelectedDate(null);
    setInputValue('');
    setOpen(true);

  };

  const onScanClick = () => {
    navigate(`/Scan-result?module=${(selectedCardData as any)?.module}&data=${inputValue}&optValue=${optionalInputValue}`);
  };

  /*function formatPhoneNumber(value) {
    // Remove todos os caracteres não numéricos
    let cleaned = value.replace(/\D/g, '').substring(0,11); // 912345678

    if(cleaned.length == 10){
      cleaned = '(' + cleaned[0] + ') ' + cleaned.substring(1,cleaned.length)
    }else if (cleaned.length == 11){
      cleaned = '(' + cleaned[0] + cleaned[1] + ') ' + cleaned.substring(2,cleaned.length)
    }
  
    return cleaned;
  }*/

  const handleInputChange = (event: any, module: string) => {
    const inputValue = event.target.value;
    let newValue = inputValue;
    if (
      module == "CPF" ||
      module == "CNS" ||
      module == "CEP" ||
      module == "RG" ||
      module == "CNPJ"
    ) {
      newValue = inputValue.replace(/\D/g, '');
    } else if (module == "TELEFONE") {
      //newValue = formatPhoneNumber(inputValue)
      newValue = inputValue.replace(/\D/g, '');
    } else if (module == "NOME" || module == "Mae") {
      newValue = inputValue.replace(/[^a-zA-Z\u00C0-\u00FF\sçãáéíóúÇÃÁÉÍÓÚ]/g, '');
    } else if (module == "PLACA") {
      newValue = inputValue.toUpperCase().replace(/[^A-Z0-9]/g, '').substring(0, 7);
    }
    setInputValue(newValue);
  };

  const handleOptionalInputChange = (event: any, module: string) => {
    const optionalInputValue = event.target.value;
    let newValue = optionalInputValue;
    if (
      module == "CEP"
    ) {
      newValue = optionalInputValue.replace(/\D/g, '');
    }
    setOptionalInputValue(newValue);
  };

  const [alertType, setAlertType] = React.useState(0);
  const [alertPreText, setAlertPreText] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  const handleCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const [selectedDate, setSelectedDate] = React.useState(null);
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : null
    setOptionalInputValue(formattedDate);
  };

  return (
    <Box style={{ height: "82%" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <IconButton onClick={handleClose} aria-label="delete"><CloseIcon /></IconButton>
          </Box>
          <Typography id="modal-modal-title" style={{ marginTop: "5%", marginBottom: "2%", textAlign: "left" }} variant="h6" component="h2">
            {(selectedCardData as any)?.title}
          </Typography>

          <form action="#" onSubmit={(event) => { onScanClick(); event.preventDefault() }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "2%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    required={true}
                    id="outlined-required"
                    label={(selectedCardData as any)?.input}
                    placeholder={(selectedCardData as any)?.placeholder}
                    value={inputValue}
                    onChange={(event) => handleInputChange(event, (selectedCardData as any)?.module)}
                  />
                </Grid>

                {selectedCardData?.module == "CEP" ?
                  (
                    <Grid item xs={12} style={{ marginBottom: "2%" }}>
                      <TextField
                        label="Número (Opcional)"
                        placeholder="167"
                        value={optionalInputValue}
                        onChange={(event) => handleOptionalInputChange(event, (selectedCardData as any)?.module)}
                      />
                    </Grid>
                  ) : selectedCardData?.module == "PIX" ?
                    (
                      <Grid item xs={12} style={{ marginBottom: "2%" }}>
                        <TextField
                          required={true}
                          label="CPF (xxx.374.473.xx)"
                          placeholder="***.374.473.**"
                          value={optionalInputValue}
                          onChange={(event) => handleOptionalInputChange(event, (selectedCardData as any)?.module)}
                        />
                      </Grid>
                    ) : selectedCardData?.module == "NOME" ? (
                      <Grid item xs={12} style={{ marginBottom: "2%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            label="Nascimento (Opcional)"
                            value={selectedDate}
                            onChange={handleDateChange}
                            format="DD/MM/YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      </Grid>
                    ) : (<></>)}

                <Grid item xs={4}>
                  <Button type='submit' variant="outlined" startIcon={<Fingerprint />}>SCAN</Button>
                </Grid>

              </Grid>
            </Box>
          </form>

        </Box>
      </Modal>
      <Card style={{ maxHeight: "72vh", margin: "4%", marginBottom: "1.3%", borderRadius: "2%", padding: "0.7%" }} variant="outlined">
        <React.Fragment>
          {
            isAlertVisible && (
              <div className={alertType == 0 ? "alert alert-success" : alertType == 1 ? "alert alert-warning" : alertType == 2 ? "alert alert-info" : "alert alert-danger"} style={{ margin: 0, padding: 6 }}>
                <button type="button" className="btn-close" onClick={handleCloseAlert}>
                  &times;
                </button>
                <strong style={{ marginRight: '0.5%' }}>{alertPreText}: </strong>
                {alertMessage}
              </div>
            )
          }
          <CardContent className="main-scrollbar-container" style={{ marginTop: "1%", maxHeight: "64vh", overflow: "auto" }}>
            <Grid container spacing={{ xs: 2, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {Array.from(Array(data)).map((_, index) => (
                data.map((item: any, index: any) => (
                  <Grid item xs={2} sm={3} md={3} key={index}>
                    <Card onClick={() => onCardClick(item)} sx={{ maxWidth: "85%" }} style={cardStyle}>
                      <CardActionArea style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <CardMedia
                          component="img"
                          style={{ height: "15vh", width: '100%' }} // or use a specific fixed height
                          image={item.image ? item.image : defaultImage}
                          alt={item.alt}
                        />
                        <CardContent className="scrollbar-container" style={{ flex: '1', overflowY: 'auto' }}>
                          <Typography gutterBottom style={{ fontSize: '170%' }} component="div">
                            {item.title}
                          </Typography>
                          <Typography variant="body2" style={{ fontSize: "105%" }} color="text.secondary">
                            {item.description}
                          </Typography>
                          <style>{`
                          /* Custom scrollbar styles */
                          .scrollbar-container::-webkit-scrollbar {
                            width: 2px; /* Width of the scrollbar */
                          }
                          .scrollbar-container::-webkit-scrollbar-track {
                            background: #f1f1f1; /* Color of the track */
                            border-radius: 6px; /* Rounded corners */
                          }
                          .scrollbar-container::-webkit-scrollbar-thumb {
                            background: #888; /* Color of the scrollbar handle */
                            border-radius: 6px; /* Rounded corners */
                          }
                          .scrollbar-container::-webkit-scrollbar-thumb:hover {
                            background: #555; /* Color of the scrollbar handle on hover */
                          }

                          .main-scrollbar-container::-webkit-scrollbar {
                            width: 8px; /* Width of the scrollbar */
                          }
                          .main-scrollbar-container::-webkit-scrollbar-track {
                            background: #f1f1f1; /* Color of the track */
                            border-radius: 6px; /* Rounded corners */
                          }
                          .main-scrollbar-container::-webkit-scrollbar-thumb {
                            background: #888; /* Color of the scrollbar handle */
                            border-radius: 6px; /* Rounded corners */
                          }
                          .main-scrollbar-container::-webkit-scrollbar-thumb:hover {
                            background: #555; /* Color of the scrollbar handle on hover */
                          }
                        `}</style>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>

                ))
              ))}
            </Grid>
          </CardContent>
        </React.Fragment>
      </Card>
      <Footer></Footer>
    </Box>
  );
}