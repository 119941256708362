"use client";

import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, BrowserRouter } from 'react-router-dom';
import MainCard from './pages/homePage.tsx';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ResponsiveAppBar from './appbar.tsx';
import App from './particle.tsx'
import ScanResult from './pages/scanResultsPage.tsx';
import Planos from './pages/subscriptionPage.tsx';
import Termos from './pages/termsPage.tsx';
import Sobre from './pages/aboutUsPage.tsx';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TabContext from '@mui/lab/TabContext';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import { useAuth } from './authentication/authContext.tsx';
import { cadastro } from '../services/authService.ts'
import Perfil from './pages/profilePage.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Contato from './pages/contatoPage.tsx';
import CircularProgress from '@mui/material/CircularProgress';
import SecurityIcon from '@mui/icons-material/Security';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import LockResetIcon from '@mui/icons-material/LockReset';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import config from '../configs/config.js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReCAPTCHA from 'react-google-recaptcha';
import Captcha, { CaptchaHandle } from './authentication/captcha.tsx';

export default function MainComponent() {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setCadastroFormData({
      usuario: '',
      senha: '',
      confirmarSenha: ''
    });
    setUserDirty(false);
    setSenhaDirty(false);
    setSenhaError(false);
    setUserError(false);
    setConfirmaSenhaDirty(false);
    setUsernameErrorText("");
    setSenhaErrorText("")
    setLoginFormData({
      usuario: '',
      senha: ''
    });
    setOpen(true);
  };

  const handleClick = () => {
    if (isMaintenance){
      alert('Website em manutenção. Volte mais tarde!');
    }
  };

  const [captchaValue, setCaptchaValue] = React.useState('invalid');
  const [captchaId, setCaptchaId] = React.useState('');

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleCaptchaIdChange = (value) => {
    setCaptchaId(value);
  };

  const passwordRuleText = "8-64 caracteres (A-Z,a-z,0-9, no mínimo 1 de cada)";

  const [value, setValue] = React.useState("1"); // modal tabs
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const swapState = (value: string) => { // modal tabs
    setValue(value);
  };

  const Modalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "18%",
    maxHeight: value == "2" ? '80%' : '60%',
    minWidth: "40vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "white",
    overflow: "auto"
  };

  const [cadastroFormData, setCadastroFormData] = React.useState({
    usuario: '',
    senha: '',
    confirmarSenha: ''
  });

  const [isUserDirty, setUserDirty] = React.useState(false);
  const [isSenhaDirty, setSenhaDirty] = React.useState(false);
  const [isConfirmaSenhaDirty, setConfirmaSenhaDirty] = React.useState(false);

  const ChildTokenModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    minWidth: "40vh",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    color: "white",
    overflow: "auto"
  };

  interface ChildTokenModalProps {
    open: boolean;
    handleClose: () => void;
  }

  const [recovery_token, setRecoveryToken] = React.useState("");

  function ChildTokenModal({ open, handleClose }: ChildTokenModalProps) {

    const downloadToken = () => {
      const element = document.createElement('a');
      const file = new Blob([recovery_token], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = "IMPORTANTE-life-scan_Token_De_Recuperacao.txt";
      document.body.appendChild(element);
      element.click();
    };

    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...ChildTokenModalStyle, width: "20%", borderRadius: "2%", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <SecurityIcon style={{ fontSize: "300%" }}></SecurityIcon>
            <h2 id="child-modal-title" style={{ marginTop: "4%" }}>Token de Recuperação</h2>
            <p id="child-modal-description" style={{ marginTop: "-2%", fontSize: "13px", textAlign: 'center' }}>
              Armazene o token de recuperação de forma segura para possibilitar a redefinição da sua conta e a criação de uma nova senha caso você a esqueça.
            </p>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop: "-2%" }}>
              <textarea
                value={recovery_token}
                readOnly
                rows={10}
                cols={50}
                style={{
                  resize: 'none',
                  width: '100%',
                  height: "2vh",
                  backgroundColor: '#222',
                  color: 'white',
                  padding: "1%",
                  border: 'none',
                  marginRight: '8px',
                  textAlign: 'center'
                }}
              ></textarea>
              <IconButton onClick={downloadToken} style={{ color: '#fff', backgroundColor: '#222' }}>
                <FileDownloadIcon />
              </IconButton>
            </div>
            <p id="child-modal-description" style={{ marginTop: "3%", fontSize: "10px", textAlign: 'center', color: "red" }}>
              Atenção: Para sua segurança e anonimato não possuimos método de recuperação de senha via telefone ou e-mail.
            </p>
            <Button onClick={handleClose} style={{ marginTop: "3%" }}>Salvei meu token em local seguro</Button>
          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  function ChildRecoveryModal({ open, handleClose }: ChildTokenModalProps) {

    const [recoveryToken, setRecoveryToken] = React.useState('');
    const [recoveryUsername, setRecoveryUsername] = React.useState('');

    const handleRecoveryTokenChange = (event) => {
      setRecoveryToken(event.target.value);
    };

    const handleRecoveryUserChange = (event) => {
      setRecoveryUsername(event.target.value);
    };

    const [senhaRecuperada, setSenhaRecuperada] = React.useState("");
    const [recuperouSenha, setRecuperouSenha] = React.useState(false);
    const recuperaSenha = async () => {
      if (recoveryUsername == null || recoveryToken == null)
        return
      if (!captchaValue || captchaValue.length == 0) { setCaptchaValue("invalid") }
      try {
        const response = await fetch(config.apiUrl + `/RecuperaConta?captchaResponse=${captchaValue}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            usuario: recoveryUsername,
            token: recoveryToken
          })
        });

        if (response.ok) {
          const responseData = await response.json();
          setSenhaRecuperada(responseData.novaSenha);
          setRecuperouSenha(true);
          toast.success("Recuperação de conta efetuada com sucesso.")
        } else if (response.status === 404) {
          toast.error("Ops! Dados incorretos.")
        } else {
          const errorMessage = await response.text();
          toast.error(errorMessage);
        }
      } catch (e) {
        toast.error("Ops! Falha de comunicação com o servidor.")
      }
    }

    const [recuperandoSenha, setRecuperandoSenha] = React.useState(0);
    const handleRecuperaSenha = () => {
      toast.promise(
        new Promise(async (resolve, reject) => {
          setRecuperandoSenha(1);
          await recuperaSenha();
          setRecuperandoSenha(0);
          if (recuperandoSenha) {
            resolve("");
          } else {
            reject();
          }
        }),
        {
          pending: "Alterando senha..."
        }
      );
    }

    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{
            ...ChildTokenModalStyle, width: "20%", borderRadius: "2%",
            filter: recuperandoSenha == 1 ? 'blur(3px)' : 'none', pointerEvents: recuperandoSenha == 1 ? 'none' : 'auto',
            padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "33%"
          }}>
            {recuperandoSenha ?
              (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999
                }}>
                  <CircularProgress />
                </div>
              ) :

              !(recuperouSenha) ? (
                <>
                  <LockResetIcon style={{ fontSize: "300%" }}></LockResetIcon>
                  <h2 id="child-modal-title" style={{ marginTop: "0.0%" }}>Recuperar Senha</h2>
                  <p id="child-modal-description" style={{ marginTop: "0.0%", fontSize: "12px", textAlign: 'center' }}>
                    Informe o seu token e nome de usuário para recuperação de senha:
                  </p>

                  <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop: "3%" }}>
                    <textarea
                      rows={10}
                      cols={50}
                      placeholder='USUÁRIO'
                      style={{
                        resize: 'none',
                        width: '100%',
                        height: "2vh",
                        backgroundColor: '#222',
                        color: 'white',
                        padding: "1%",
                        border: 'none',
                        marginRight: '8px',
                        textAlign: 'center'
                      }}
                      value={recoveryUsername}
                      onChange={handleRecoveryUserChange}
                    ></textarea>
                    <textarea
                      rows={10}
                      cols={50}
                      placeholder='TOKEN'
                      style={{
                        resize: 'none',
                        width: '100%',
                        height: "2vh",
                        backgroundColor: '#222',
                        color: 'white',
                        padding: "1%",
                        border: 'none',
                        marginRight: '8px',
                        textAlign: 'center'
                      }}
                      value={recoveryToken}
                      onChange={handleRecoveryTokenChange}
                    ></textarea>
                  </div>
                  <p id="child-modal-description" style={{ marginTop: "3%", fontSize: "10px", textAlign: 'center', color: "red" }}>
                    Atenção: Esta é a única forma de recuperação. Caso não possua o token ou username, precisará criar uma nova conta.
                  </p>
                  <div style={{ transform: "scale(1, 0.89)", transformOrigin: "0 0", marginTop: "2%" }}>
                    {/*RECOVERY CAPTCHA*/}
                  </div>
                  <Button onClick={handleRecuperaSenha} style={{ marginTop: "3%" }}>Restaurar minha senha</Button>
                </>
              ) : (
                <>
                  <CheckCircleOutlineIcon style={{ fontSize: "300%", color: "#4ecc6f" }}></CheckCircleOutlineIcon>
                  <h2 id="child-modal-title" style={{ marginTop: "0.0%", color: "#4ecc6f" }}>Senha Recuperada</h2>
                  <p id="child-modal-description" style={{ marginTop: "0.0%", fontSize: "12px", textAlign: 'center' }}>
                    Utilize a senha abaixo para realizar login.
                  </p>
                  <textarea
                    readOnly
                    rows={10}
                    cols={50}
                    style={{
                      resize: 'none',
                      width: '100%',
                      height: "2vh",
                      backgroundColor: '#222',
                      color: 'white',
                      padding: "1%",
                      border: 'none',
                      marginRight: '8px',
                      textAlign: 'center'
                    }}
                    value={senhaRecuperada}
                  ></textarea>
                  <p id="child-modal-description" style={{ marginTop: "3%", fontSize: "10px", textAlign: 'center', color: "red" }}>
                    Atenção: Não se esqueça de alterar a senha assim que possível, no perfil de usuário.
                  </p>
                </>
              )}

          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  // Your handleCadastroFieldsChange function
  const handleCadastroFieldsChange = (fieldName: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {

    switch (fieldName) {
      case "usuario":
        setUserDirty(true);
        break;
      case "senha":
        setSenhaDirty(true);
        break;
      case "confirmarSenha":
        setConfirmaSenhaDirty(true);
        break;
    }
    await new Promise<void>(resolve => {
      setCadastroFormData(prevState => {
        const newState = { ...prevState, [fieldName]: event.target.value };
        validaUserName(newState);
        validaSenha(newState, fieldName);
        return newState;
      });
      resolve();
    });
    //setCadastroFormData({ ...cadastroFormData, [fieldName]: event.target.value });
  };

  const handleLoginFieldsChange = (fieldName: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (fieldName) {
      case "usuario":
        setUserDirty(true);
        break;
      case "senha":
        setSenhaDirty(true);
        break;
      case "confirmarSenha":
        setConfirmaSenhaDirty(true);
        break;
    }
    await new Promise<void>(resolve => {
      setLoginFormData(prevState => {
        const newState = { ...prevState, [fieldName]: event.target.value };
        validaUserName(newState);
        //validaSenha(newState, fieldName);
        return newState;
      });
      resolve();
    });
  };

  const handleChildTokenOpen = () => {
    setChildTokenOpen(true);
  };

  const handleChildTokenClose = () => {
    setChildTokenOpen(false);
    swapState("1");
  };

  const handleChildRecoveryOpen = () => {
    setChildRecoveryOpen(true);
  };

  const handleChildRecoveryClose = () => {
    setChildRecoveryOpen(false);
  };

  const [childTokenOpen, setChildTokenOpen] = React.useState(false);
  const [childRecoveryOpen, setChildRecoveryOpen] = React.useState(false);

  const [creatingAccount, setCreatingAccount] = React.useState(0);

  const handleCadastro = async (e: any) => {
    e.preventDefault();
    if (senhaError || userError) {
      toast.error("Ops! Confira os campos e tente novamente.")
      return;
    }
    e.preventDefault();
    toast.promise(
      new Promise(async (resolve, reject) => {

        let createAccountResult: boolean;
        setCreatingAccount(1);
        if (!captchaValue || captchaValue.length == 0) { setCaptchaValue("invalid") }
        createAccountResult = await cadastro(cadastroFormData, captchaId, captchaValue);
        setCreatingAccount(0);
        if (createAccountResult) {
          resolve("");
          var recToken = localStorage.getItem('RecoveryToken');
          if (recToken != null) {
            setRecoveryToken(recToken);
          } else {
            toast.error("Ops! Erro ao gerar token de recuperação de senha. Contacte um administrador!")
          }
          localStorage.removeItem('RecoveryToken');
          handleChildTokenOpen();
        } else {
          reloadCaptcha();
          reject();
        }
      }),
      {
        pending: "Criando nova conta..."
      }
    );
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const [LoginFormData, setLoginFormData] = React.useState({
    usuario: '',
    senha: ''
  });

  //const navigate = useNavigate();

  const childRef = useRef<CaptchaHandle>(null);
  const reloadCaptcha = () => {
    // Acessa o método exposto pelo componente filho
    if (childRef.current) {
      childRef.current.fetchCaptcha();
    }
  };

  const [loggingIn, setLoggingIn] = React.useState(0);

  const handleLogin = async (e: any) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        e.preventDefault();

        let loginResult: boolean;
        setLoggingIn(1);
        if (!captchaValue || captchaValue.length == 0) { setCaptchaValue("invalid") }
        loginResult = await login(LoginFormData, captchaId, captchaValue);
        setLoggingIn(0);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }

        if (loginResult) {
          resolve("");
          handleClose();
        } else {
          reloadCaptcha();
          reject("Erro ao fazer login. Verifique suas credenciais e tente novamente.");
        }
      }),
      {
        pending: "Efetuando login..."
      }
    );
  };


  const [usernameErrorText, setUsernameErrorText] = React.useState("");
  const [userError, setUserError] = React.useState(false);
  const validaUserName = (newState: any) => {
    let user = newState.usuario;
    setUserError(false);
    setUsernameErrorText("");

    // Expressão regular para verificar se o nome de usuário contém apenas letras e números
    const alphanumericRegex = /^[a-zA-Z0-9]+$/; // corrigir

    // Verifica se o comprimento do nome de usuário está entre 5 e 20 caracteres
    if (user.length < 5 || user.length > 20) {
      setUserError(true);
      setUsernameErrorText("O usuário deve conter no mínimo 5 e no máximo 20 caracteres.");
      return
    }

    // Verifica se o nome de usuário contém apenas letras e números
    if (!alphanumericRegex.test(user)) {
      setUserError(true);
      setUsernameErrorText("O usuário deve conter apenas letras e/ou números.");
      return
    }
  };

  const [senhaErrorText, setSenhaErrorText] = React.useState("");
  const [senhaError, setSenhaError] = React.useState(false);
  const validaSenha = (newState: any, field: string) => {
    let senha = field == "senha" ? newState.senha : newState.confirmarSenha;
    setSenhaError(false);
    setSenhaErrorText("");


    // Verifica se o comprimento da senha está entre 8 e 64 caracteres
    if (senha.length < 8 || senha.length > 64) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter no mínimo 8 e no máximo 64 caracteres.");
      return;
    }

    // Verifica se a senha contém pelo menos uma letra minúscula
    if (!/[a-z]/.test(senha)) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter pelo menos uma letra minúscula.");
      return;
    }

    // Verifica se a senha contém pelo menos uma letra maiúscula
    if (!/[A-Z]/.test(senha)) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter pelo menos uma letra maiúscula.");
      return;
    }

    // Verifica se a senha contém pelo menos um número
    if (!/\d/.test(senha)) {
      setSenhaError(true);
      setSenhaErrorText("A senha deve conter pelo menos um número.");
      return;
    }

    if ((isSenhaDirty && isConfirmaSenhaDirty) && (newState.senha != newState.confirmarSenha)) {
      setSenhaError(true);
      setSenhaErrorText("As senhas não coincidem.");
      return;
    }
  };

  const { login } = useAuth();

  const recaptchaRef = React.useRef<ReCAPTCHA>();
  const [isMaintenance, setIsMaintenance] = React.useState(false);

  return (
    <div onClick={handleClick}>
      <div style={{ pointerEvents: isMaintenance ? 'none' : 'auto' }}>
        <Router>
          <ResponsiveAppBar openModal={handleOpen} swapTab={swapState}></ResponsiveAppBar>
          <Modal style={{ margin: 0, padding: 0 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <TabContext value={value}>
                <Box sx={{
                  width: '100%', filter: loggingIn === 1 || creatingAccount == 1 ? 'blur(3px)' : 'none',
                  pointerEvents: loggingIn === 1 || creatingAccount == 1 ? 'none' : 'auto'
                }}>
                  {loggingIn == 1 || creatingAccount == 1 ? (
                    <div style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 9999
                    }}>
                      <CircularProgress />
                    </div>
                  ) : (<></>)}
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab style={{ width: "50%" }} icon={<LoginIcon />} iconPosition="start" label="Log-in" value="1" />
                      <Tab style={{ width: "50%" }} icon={<PersonAddAlt1Icon />} iconPosition="start" label="Nova Conta" value="2" />
                    </Tabs>
                  </Box>
                  <TabPanel value="1">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "12" }}>
                      <form onSubmit={handleLogin} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <TextField
                          onChange={handleLoginFieldsChange('usuario')}
                          value={LoginFormData.usuario}
                          error={userError && isUserDirty}
                          style={{ width: "100%" }}
                          required
                          id="outlined-required"
                          label="Usuário"
                          variant="outlined"
                          placeholder="Usuário"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          onChange={handleLoginFieldsChange('senha')}
                          value={LoginFormData.senha}
                          style={{ width: "100%", marginTop: "5%" }}
                          required
                          id="outlined-required"
                          label="Senha"
                          variant="outlined"
                          placeholder="Senha"
                          type="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div style={{ marginTop: "4%", width: "100%"}}>
                          {/*LOGIN CAPTCHA*/}
                          <Captcha ref={childRef} onCaptchaIdChange={handleCaptchaIdChange} onCaptchaChange={handleCaptchaChange} />
                        </div>
                        <Button disabled={userError && isUserDirty} type="submit" variant="contained" style={{ marginTop: "3%", width: "100%", height: "5vh" }} >LOG-IN</Button>
                        <ChildRecoveryModal open={childRecoveryOpen} handleClose={handleChildRecoveryClose} />
                      </form>

                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
                      <Button onClick={() => { handleChildRecoveryOpen() }}>Esqueceu a senha ?</Button>
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <form onSubmit={handleCadastro} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <TextField
                          onChange={handleCadastroFieldsChange('usuario')}
                          value={cadastroFormData.usuario}
                          style={{ width: "100%", marginTop: "1%" }}
                          required
                          error={userError && isUserDirty}
                          helperText={isUserDirty ? usernameErrorText : ""}
                          id="outlined-required"
                          label="Usuário"
                          variant="outlined"
                          placeholder="Usuário"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          onChange={handleCadastroFieldsChange('senha')}
                          value={cadastroFormData.senha}
                          style={{ width: "100%", marginTop: "6%" }}
                          error={senhaError && isSenhaDirty}
                          helperText={isSenhaDirty ? senhaErrorText : ""}
                          required
                          id="outlined-required"
                          label="Senha"
                          variant="outlined"
                          placeholder="Senha"
                          type="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {isSenhaDirty === true && senhaError === true ? (
                          <div style={{ display: 'flex', marginTop: "1%", alignItems: 'center' }}>
                            <InfoIcon style={{ fontSize: 'medium', marginLeft: '2%', color: senhaError ? "#992f27" : "gray" }} />
                            <Typography variant="body2" style={{ fontSize: '12px', color: senhaError ? "#db3e32" : "gray", marginLeft: "1%" }}>
                              {passwordRuleText}
                            </Typography>
                          </div>
                        ) : (
                          <></>
                        )}
                        <TextField
                          onChange={handleCadastroFieldsChange('confirmarSenha')}
                          value={cadastroFormData.confirmarSenha}
                          style={{ width: "100%", marginTop: "6%" }}
                          error={senhaError && isConfirmaSenhaDirty}
                          helperText={isConfirmaSenhaDirty ? senhaErrorText : ""}
                          required
                          id="outlined-required"
                          label="Confirmar Senha"
                          variant="outlined"
                          placeholder="Confirmar Senha"
                          type="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div style={{ marginTop: "4%", width: "100%"}}>
                          {/*REGISTER CAPTCHA*/}
                          <Captcha ref={childRef} onCaptchaIdChange={handleCaptchaIdChange} onCaptchaChange={handleCaptchaChange} />
                        </div>
                        <Button
                          //disabled={(senhaError && isConfirmaSenhaDirty) || (senhaError && isSenhaDirty) || (userError && isUserDirty)}
                          type="submit"
                          variant="contained"
                          style={{
                            marginTop: "3%",
                            width: "100%",
                            height: "5vh"
                          }}>Criar Conta
                        </Button>
                        <ChildTokenModal open={childTokenOpen} handleClose={handleChildTokenClose} />
                      </form>
                    </div>
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </Modal>
          <Routes>
            <Route path="/" element={<MainCard />} />
            <Route path="/Home" element={<MainCard />} />
            <Route path="/Page" element={<MainCard />} />
            <Route path="/Scan-result" element={<ScanResult openModal={handleOpen} swapTab={swapState} />} />
            <Route path="/Planos" element={<Planos />} />
            <Route path="/Termos" element={<Termos />} />
            <Route path="/Sobre" element={<Sobre />} />
            <Route path="/Perfil" element={<Perfil />} />
            <Route path="/Contato" element={<Contato />} />
            <Route path="*" element={<MainCard />} />
          </Routes>
        </Router>
        <App></App>
      </div>
    </div>
  );
}