import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import config from '../../configs/config.js';

const terms = config.terms;

export default function Termos() {

  return (
    <Box style={{ display: "flex", maxHeight: "93%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Card style={{ width: "75%", margin: "4%", overflowY: "auto", overflowX: "hidden", borderRadius: "2%", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} variant="outlined">
        <ReceiptLongIcon style={{ fontSize: "300%", marginTop: "3%" }}></ReceiptLongIcon>
        <div style={{ display: "flex", alignItems: "center", marginTop: "2%", marginBottom: "3%" }}>
          <hr style={{ color: "white", width: "20vh", marginRight: "1%", flex: "1" }} />
          <h1 style={{ marginTop: "0", marginBottom: "0", marginLeft: "0", marginRight: "0", flex: "none", textAlign: "center" }}>TERMOS DE USO</h1>
          <hr style={{ color: "white", width: "20vh", marginLeft: "1%", flex: "1" }} />
        </div>
        <p style={{ marginTop: "1%" }}>Ao utilizar nossa aplicação web de consultas, você concorda em cumprir estes Termos de Uso e quaisquer políticas adicionais estabelecidas pela plataforma.</p>
        <br />
        <textarea
          value={terms}
          readOnly
          rows={10} // Define o número de linhas
          cols={50} // Define o número de colunas
          style={{
            resize: 'none',
            width: '95%',
            minHeight: '230px',
            backgroundColor: '#222', // Dark background color
            color: '#fff', // White text color
            padding: "1%",
            border: 'none' // Remove border (optional)
          }}
        />
        <Typography variant="body2" style={{ marginTop: "3%" }}>Atenção: Ao utilizar nossa aplicação web, você concorda com estes Termos de Uso e compromete-se a seguir todas as políticas e diretrizes estabelecidas pela
          plataforma. O não cumprimento destes Termos de Uso pode resultar em ações disciplinares, incluindo a suspensão ou encerramento da conta.</Typography>
      </Card>
    </Box>
  );
}