const config = {
    apiUrl: 'https://publicdataqueryapi.top/api',
    availableModules: ['CPF', 'NOME', 'PLACA', 'MAE', 'CNS', 'TELEFONE', 'CEP', 'CNPJ', 'EMAIL', 'PIX', 'RG'],
    terms: `1. Proibição do Uso de Bots:
O uso de bots ou qualquer forma de automação para realizar consultas em nossa plataforma é estritamente proibido. 
Caso seja detectado o uso de bots, a conta será banida imediatamente, sem direito a reembolso.

2. Limite de Consultas:
Os usuários terão um limite pré-estabelecido de 100 consultas por hora. Ultrapassar este limite resultará no bloqueio 
temporário das consultas por um período de 20 minutos. Este limite é implementado para garantir um uso justo e equitativo da plataforma para todos os usuários.

3. Responsabilidades do Usuário:
Os usuários são responsáveis por manter a segurança de suas contas e informações de login. Qualquer atividade suspeita ou não autorizada deve ser relatada 
imediatamente à equipe de suporte da plataforma.

4. Uso Adequado dos Dados:
Os dados obtidos por meio de consultas em nossa plataforma devem ser utilizados de acordo com as leis e regulamentações aplicáveis. O compartilhamento ou uso 
indevido dos dados obtidos é estritamente proibido.

5. Alterações nos Termos de Uso:
Reservamo-nos o direito de alterar ou atualizar estes Termos de Uso a qualquer momento, sem aviso prévio. Recomendamos que os usuários revisem periodicamente os 
Termos de Uso para estar cientes de quaisquer alterações.`
  };
  
  export default config;
  