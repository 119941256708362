import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UserData } from '../../interfaces/userDataInterface.ts';
import config from '../../configs/config.js';
import { cadastro } from '../../services/authService.ts'

interface AuthContextType {
  isLoggedIn: boolean;
  userData: UserData | null;
  login: (LoginFormData: any, CaptchaId: any, CaptchaText: any) => Promise<boolean>;
  trocaSenha: (trocaSenhaFormData: any) => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);

  const trocaSenha = async (trocaSenhaFormData: any) => {
    let success: boolean = false;
    const bearerToken = localStorage.getItem("token");
    if (bearerToken == null) {
      toast("Erro: Por favor efetue um novo login.");
    } else {
      try {
        const response = await fetch(config.apiUrl + '/setUserData/changePassword', {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(trocaSenhaFormData)
        });
        if (response.status === 401) {
          logout();
          toast.error("Ops! Sessão expirada. Por favor, faça login novamente.");
          return false;
        }
        if (!response.ok) {
          const errorMessage = await response.text();
          toast.error(errorMessage);
        } else {
          success = true;
          toast.success('Senha alterada com sucesso!');
        }
      } catch (error) {
        toast.error("Ops! Falha ao alterar senha. Tente novamente mais tarde.")
      }
    }
    return success;
  }

  const fetchUserData = async () => {
    let success = false;
    try {
      const token = localStorage.getItem('token');
      fetch(config.apiUrl + '/getUserData/all', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            success = true;
          }
          return response.json();
        })
        .then(data => {
          if (new Date(data.plano.data_expira) < new Date()) {
            data.plano.plano = 0;
          }
          setUserData(data);
          localStorage.setItem('userData', JSON.stringify(data));
        })
        .catch(error => { });
    } catch (error) { }
    return success;
  }

  const login = async (LoginFormData: any, CaptchaId: any, CaptchaText: any) => {

    let login: boolean = false;

    try {
      const response = await fetch(config.apiUrl + `/Login?CaptchaId=${CaptchaId}&CaptchaText=${CaptchaText}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(LoginFormData)
      });
      
      if (!response.ok) {
        const errorMessage = await response.text();
        toast.error(errorMessage);
      } else {
        const data = await response.json();
        const token = data.token;
        localStorage.setItem('token', token);
        let savedUserData = fetchUserData();
        if (!savedUserData) {
          toast.error("Ops! Falha ao obter dados do usuário.");
          return false;
        }
        login = true;
        setIsLoggedIn(true);
        toast.success('Login realizado com sucesso!');
      }
    } catch (error) {
      toast.error("Ops! Falha de comunicação com o servidor.")
    }
    return login
  }

  const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // check if token still valid
        fetch(config.apiUrl + `/Login/isTokenActive?token=${token}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(response => {
            if (!response.ok) {
              setIsLoggedIn(false);
              toast.error("Ops! Sessão expirada, por favor realize um novo login.");
              localStorage.removeItem('token');
              localStorage.removeItem('userData');
            } else {
              fetchUserData();
              setIsLoggedIn(true);
            }
            return response.json();
          })
          .then(data => {
          })
          .catch(error => { });
      } catch (error) { }
    }
    const storeduserData = localStorage.getItem('userData');
    if (storeduserData) {
      setUserData(JSON.parse(storeduserData));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, userData, login, logout, trocaSenha }}>
      {children}
    </AuthContext.Provider>
  );
};