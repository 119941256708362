import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import config from '../../configs/config.js';
import CircularProgress from '@mui/material/CircularProgress';

interface CaptchaProps {
  onCaptchaChange?: (value: string) => void;
  onCaptchaIdChange?: (id: string) => void;
}

// Define a interface para a referência do componente filho
export interface CaptchaHandle {
  fetchCaptcha: () => void;
}

const Captcha = forwardRef<CaptchaHandle, CaptchaProps>(({ onCaptchaChange, onCaptchaIdChange }, ref) => {
  const [captchaImage, setCaptchaImage] = useState('');
  const [userInput, setUserInput] = useState('');
  const [captchaId, setCaptchaId] = useState('');

  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const handleCaptchaChange = (event) => {
    setUserInput(event.target.value);
    if (onCaptchaChange) {
      onCaptchaChange(event.target.value);
    }
  };

  const fetchCaptcha = async () => {
    setUserInput("")
    setLoadingCaptcha(true);
    try {
      const response = await fetch(config.apiUrl + '/SimpleCaptcha/generate');
      const data = await response.json();
      setCaptchaImage(data.imageData);
      setCaptchaId(data.captchaId);
      if (onCaptchaIdChange) {
        onCaptchaIdChange(data.captchaId);
      }
    } catch (error) {
      console.error('Error fetching CAPTCHA:', error);
    }
    setLoadingCaptcha(false);
  };

  useImperativeHandle(ref, () => ({
    fetchCaptcha
  }));

  useEffect(() => {
    fetchCaptcha();
  }, []);

  return (
      <div style={{ marginBottom: "5%", marginTop: "5%", width: "100%" }}>
        {loadingCaptcha ?
          (
            <div style={{
              zIndex: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%' // Isso ajuda a centralizar verticalmente
            }}>
              <CircularProgress />
            </div>
          ) : (<img src={captchaImage} alt="CAPTCHA" />)}
        <form>
          <TextField
            onChange={handleCaptchaChange}
            placeholder="Digite o CAPTCHA"
            value={userInput}
            variant="outlined"
            style={{ width: "100%", marginTop: "3%" }}
            InputProps={{
              style: {
                height: '40px', // Defina a altura desejada
              },
            }}
          />
          <TextField style={{ display: 'none' }} value={captchaId}></TextField>
        </form>
      </div>
  );
  
});

export default Captcha;
