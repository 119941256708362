import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';

export default function Sobre() {

  return (
    <Box>
      <Card style={{
        height: "100%",
        margin: "4%",
        borderRadius: "2%",
        padding: "3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }} variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" noWrap component="a" href="/" sx={{
              mr: 2,
              display: 'inline',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
              PROJETO LIFE-SCAN
            </Typography>
            <Typography style={{ opacity: 0.7 }} variant="body2">Informações Públicas ao Seu Alcance.</Typography>
            <div style={{ marginTop: "6%" }}>
              <Typography variant="h5"><span style={{ marginRight: '8px' }}>•</span> O que é</Typography>
              <Typography variant="subtitle1" style={{ width: "90%", marginTop: "1%" }}>
                LIFE-SCAN é um projeto ambicioso dedicado à criação da mais avançada plataforma centralizada para consulta de dados publicamente disponíveis no Brasil.
              </Typography><br />
              <Typography variant="h5"><span style={{ marginRight: '8px' }}>•</span> Objetivo</Typography>
              <Typography variant="subtitle1" style={{ width: "90%", marginTop: "1%" }}>
                Nossa missão é oferecer uma experiência excepcional ao reunir informações de maneira acessível e eficiente, proporcionando aos usuários acesso fácil a uma ampla gama de dados relevantes. Com tecnologia de ponta e uma equipe dedicada, estamos determinados a estabelecer LIFE-SCAN como o recurso definitivo para a consulta de dados no Brasil.
              </Typography><br />
              <Typography variant="h5"><span style={{ marginRight: '8px' }}>•</span> Equipe</Typography>
              <Typography variant="subtitle1" style={{ width: "90%", marginTop: "1%" }}>
                Na LIFE-SCAN, contamos com um time dedicado de desenvolvedores e analistas de segurança da informação. Nossa equipe é composta por profissionais apaixonados e comprometidos com a missão de fornecer soluções inovadoras e seguras para nossos usuários. Trabalhamos incansavelmente para garantir a excelência em cada aspecto de nosso serviço, capacitando nossos clientes a acessarem e utilizarem dados com confiança e tranquilidade.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={5} style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "70vh"
          }}>
            <img
              src="https://c1.wallpaperflare.com/preview/513/460/937/stock-people-business-work.jpg"
              alt="Your Image"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: "5%"
              }}
            />
            <Grid container spacing={1} style={{ marginTop: "5%", width: "100%" }}>
              <Grid item xs={6} sm={3}>
                <Card style={{ height: "90%" }}>
                  <CardContent style={{ height: "65%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">+200M</Typography>
                    <Typography variant="body2">De dados disponíveis.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Card style={{ height: "90%" }}>
                  <CardContent style={{ height: "65%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">+2K</Typography>
                    <Typography variant="body2">Clientes registrados.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Card style={{ height: "90%" }}>
                  <CardContent style={{ height: "65%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">+3K</Typography>
                    <Typography variant="body2">Usuários cadastrados.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Card style={{ height: "90%" }}>
                  <CardContent style={{ height: "65%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">+200K</Typography>
                    <Typography variant="body2">Consultas todo mês.</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}